export default class MetaPaginationSkeletton {
  public count: number;

  public currentPage: number;

  public perPage: number;

  public total: number;

  public totalPages: number;

  public constructor() {
    this.count = 0;
    this.currentPage = 0;
    this.perPage = 0;
    this.total = 0;
    this.totalPages = 0;
  }
}
