import AjaxService from '@services/AjaxService'
import Form from '@form/Form'

export default class PotentialCheckRepository {
  private readonly ajaxService: AjaxService

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService
  }

  public get(recordId: string): Promise<any> {
    const url = this.apiUrl(recordId)
    return this.ajaxService.getJson(url)
  }

  public update(recordId: string, form: Form): Promise<any> {
    const url = this.apiUrl(recordId)
    return this.ajaxService.putFormData(url, form)
  }

  private apiUrl(recordId: string): string {
    return '/api/records/' + recordId + '/potential-check'
  }
}
