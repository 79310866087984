import AjaxService from '@services/AjaxService';
import CostUnitStatisticGroupEnum from '@js/enums/damage/CostUnitStatisticGroupEnum';

export default class CostUnitStatisticsRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public static getOrderedGroups(): string[] {
    return [
      CostUnitStatisticGroupEnum.WORK,
      CostUnitStatisticGroupEnum.ADDITIONAL_COSTS,
      CostUnitStatisticGroupEnum.PAINTING,
      CostUnitStatisticGroupEnum.SPARE_PARTS,
      CostUnitStatisticGroupEnum.MISCELLANEOUS,
    ];
  }

  public show(recordId: number, costUnitId: number): Promise<any> {
    const url = this.buildStatisticsUrl(recordId, costUnitId);
    return this.ajaxService.getJson(url);
  }

  public update(recordId: number, costUnitId: number, attributes: any): Promise<any> {
    const url = this.buildStatisticsUrl(recordId, costUnitId);
    return this.ajaxService.postJson(url, {data: {attributes}});
  }

  private buildStatisticsUrl(recordId: number, costUnitId: number) {
    return `/api/records/${recordId}/costunits/${costUnitId}/statistics`;
  }
}
