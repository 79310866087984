var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-multiselect",
    _vm._g(
      _vm._b(
        {
          ref: "multiselect",
          on: { open: _vm.handleOpen },
          scopedSlots: _vm._u([
            {
              key: "noOptions",
              fn: function () {
                return [_vm._v("\n        Keine Einträge vorhanden.\n    ")]
              },
              proxy: true,
            },
            {
              key: "noResult",
              fn: function () {
                return [
                  _vm._v("\n        Keine Übereinstimmungen gefunden.\n    "),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "vue-multiselect",
        { ..._vm.commonProps, ..._vm.customProps },
        false
      ),
      _vm.commonEvents
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }