import moment from 'moment';

moment.locale('de');
const dateFormat = 'YYYY-MM-DD';

// The minimum value that can be stored as Unix timestamp in the database.
const defaultMinDate = '1970-01-02';

const dateAsString = (value: string): string => {
  if (value) {
    return moment(value).format(dateFormat);
  }
  return '';
};

const dateNowAsString = (): string => {
  return moment().format(dateFormat);
};

const validateDateFormat = (date?: string): boolean => {
  if (!date) {
    return true;
  }
  return moment(date, dateFormat, true).isValid();
};

const formatDate = (value: string): string => {
  if (value) {
    return moment(value).format('L');
  }
  return '';
};

const formatDateTime = (value: string): string => {
  if (value) {
    return moment(value).format('L LT');
  }
  return '';
};

export {
  dateAsString,
  dateNowAsString,
  defaultMinDate,
  formatDate,
  formatDateTime,
  validateDateFormat
}
