var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "messages-sidebar" } },
    [
      _vm.wasExpanded
        ? _c("csp-messages-sidebar", {
            attrs: { "is-expanded": _vm.isExpanded },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "toggle-button", on: { click: _vm.toggleSidebar } },
        [_c("i", { staticClass: "inticon inticon-angle-left" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }