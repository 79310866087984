import AjaxService from '@services/AjaxService';

export default class UserPreferenceRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public countPopupDisplay(identifier: string): Promise<any> {
    return this.ajaxService.putJson(
      '/api/user-preferences/popup/' + identifier,
      {}
    )
  }

  public updateDesktopSidebarExpandedAction(messagesSidebarExpanded: boolean): Promise<any> {
    return this.ajaxService.putJson(
      '/api/user-preferences/dektop-sidebar-expanded',
      {data: {attributes: {messagesSidebarExpanded}}}
    );
  }
}
