import LoadingState from '@services/loading/LoadingState';

export default class ReloadPreventer {
  private readonly loadingState: LoadingState;

  public constructor(loadingState: LoadingState) {
    this.loadingState = loadingState;
  }

  public initialize(): void {
    // See https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    window.addEventListener('beforeunload', (e) => {
      if (this.loadingState.isLoading()) {
        this.preventReload(e);
        return;
      }

      this.allowReload(e);
    });
  }

  private allowReload(e: BeforeUnloadEvent) {
    // The absence of a returnValue property on the event will guarantee the browser unload happens
    delete e['returnValue'];
  }

  private preventReload(e: BeforeUnloadEvent) {
    // Cancel the event
    // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  }
}
