<script>
    // This is just a dummy component for registering the $bvToast element in the flash messenger.
    import Container from '@services/Container';

    export default {
        mounted() {
            const flashMessenger = Container.getInstance().getFlashMessenger();
            flashMessenger.setToaster(this.$bvToast);
        },
        render() {
            return null;
        },
    }
</script>
