<script>
    export default {
        data() {
            return {
                dragTarget: null,
            }
        },
        computed: {
            canDrop() {
                return true;
            },
            classes() {
                return this.dataRecordUrl ? 'interactive' : '';
            },
            dragDetectionListeners() {
                return {
                    'dragenter': this.handleDragStart,
                    'dragleave': this.handleDragEnd,
                    'drop': this.handleDragEnd,
                }
            },
            dragStyle() {
                if (!this.isDragging) {
                    return '';
                }

                return this.canDrop ? 'background-color: #c7d8dd;' : 'cursor: no-drop;'
            },
            isDragging() {
                return this.dragTarget !== null;
            },
        },
        methods: {
            handleDragEnd(event) {
                if (this.dragTarget !== event.target) {
                    return;
                }
                this.dragTarget = null;
            },
            handleDragStart(event) {
                this.dragTarget = event.target;
            },
        },
    }
</script>
