import HttpError from '@errors/HttpError';

export default class AjaxError {
  private readonly error: Error | HttpError;

  private readonly message: string;

  public constructor(message: string, error: Error | HttpError) {
    this.message = message;
    this.error = error;
  }

  public getError(): Error | HttpError {
    return this.error;
  }

  public getMessage(): string {
    return this.message;
  }
}
