import AjaxService from '@services/AjaxService';

export default class QuestionnaireResponseRepository {
  private readonly ajaxService: AjaxService;


  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public create(identifier: string, data: any): Promise<any> {
    const url = '/api/questionnaire-responses/' + identifier;
    return this.ajaxService.postJson(url, {data: {attributes: data}});
  }
}
