var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "invalid-feedback": _vm.invalidFeedback,
        label: _vm.label,
        "label-for": _vm.formControlId,
      },
    },
    [
      _c(
        "b-form-checkbox",
        {
          attrs: {
            id: _vm.formControlId,
            button: _vm.button,
            disabled: _vm.isReadOnlyProperty,
            name: _vm.formControlId,
            required: _vm.isRequiredProperty,
            state: _vm.validationState,
            switch: _vm.asSwitch,
            "unchecked-value": _vm.uncheckedValue,
            value: _vm.value,
          },
          on: { change: _vm.emitChange },
          model: {
            value: _vm.data[_vm.property],
            callback: function ($$v) {
              _vm.$set(_vm.data, _vm.property, $$v)
            },
            expression: "data[property]",
          },
        },
        [
          !_vm.hasLabelSlot
            ? [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t("form field checkbox " + _vm.labelPropertyValue)
                    ) +
                    "\n        "
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm._t("label"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }