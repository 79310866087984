import AjaxService from '@services/AjaxService';
import SelectOption from '@form/select/SelectOption';
import InsuranceTypeEnum from '@js/enums/insurance/InsuranceTypeEnum';

export default class InsuranceRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public findChildrenForDropdown(parentId: number, type: InsuranceTypeEnum): Promise<SelectOption[]> {
    const url = '/api/insurances/' + parentId + '/children?type=' + type;
    return this.getDropdownOptions(url);
  }

  public findParentsForDropdown(type: InsuranceTypeEnum): Promise<SelectOption[]> {
    const url = '/api/insurances?' + 'type=' + type;
    return this.getDropdownOptions(url);
  }

  private getDropdownOptions(url: string) {
    return this.ajaxService.getJson(url).then((response: any) => {
      const options: SelectOption[] = [];
      for (const optionData of response.data) {
        options.push({
          value: parseInt(optionData.id, 10),
          text: optionData.attributes.companyName,
        })
      }
      return options;
    });
  }
}
