<template>
    <b-card class="card-foldable" no-body>
        <b-link :class="collapseLinkClasses" @click="toggleVisibility">
            <span class="text-uppercase font-weight-bold foldable-headline">{{ title }}</span>
        </b-link>
        <b-collapse :id="collapseId" v-model="visible" accordion="my-accordion" role="tabpanel">
            <b-card>
                <slot v-if="visible"/>
            </b-card>
        </b-collapse>
    </b-card>
</template>

<script>
    export default {
        props: {
            collapseId: {
                type: String,
                required: true,
            },
            linkClasses: {
                type: Array,
                default() {
                    return [];
                },
            },
            title: {
                type: String,
                required: true,
            },
            collapsed: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                visible: !this.collapsed,
            }
        },
        computed: {
            collapseLinkClasses() {
                let classes = [
                    'foldable-header',
                    'text-left',
                ];

                classes = classes.concat(this.linkClasses);

                if (!this.visible) {
                    classes.push('collapsed')
                }

                return classes;
            },
        },
        methods: {
            toggleVisibility() {
                this.visible = !this.visible;
            },
        },
    }
</script>
