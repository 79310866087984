import SelectOption from '@form/select/SelectOption';
import CarRentalSourceEnum from '@js/enums/damage/CarRentalSourceEnum';
import Container from '@services/Container';
import NoRepairReasonEnum from '@js/enums/damage/NoRepairReasonEnum';
import CostUnitCutPositionTypesEnum from '@js/enums/damage/CostUnitCutPositionTypesEnum';
import GenderEnum from '@js/enums/GenderEnum';
import ItemDataSkeletton from '@js/jsonapi/skeletton/ItemDataSkeletton';
import DateRangeTypeEnum from '@js/enums/DateRangeTypeEnum';

const buildOptionsCarRentalSource = (): Promise<SelectOption[]> => {
  const loginStatus = Container.getInstance().getLoginStatusStore();

  let carRentalTranslationKeyPrefix = 'car rental source';
  if (loginStatus.isCarDealer()) {
    carRentalTranslationKeyPrefix += ' carDealer'
  }

  return buildOptionsFromEnum(CarRentalSourceEnum, carRentalTranslationKeyPrefix);
};

const buildOptionsEstimatedDamage = (): SelectOption[] => {
  const estimatedDamageValues = [
    '0 - 1.000',
    '1.000 - 2.000',
    '2.000 - 3.000',
    '3.000 - 4.000',
    '4.000 - 5.000',
    '5.000 - 6.000',
    '6.000 - 7.000',
    '7.000 - 8.000',
    '8.000 - 9.000',
    '9.000 - 10.000',
    '10.000 - 12.000',
    '12.000 - 14.000',
    '14.000 - 16.000',
    '16.000 - 18.000',
    '18.000 - 20.000',
    'über 20.000',
  ];

  return estimatedDamageValues.map(entry => ({
    value: entry,
    text: entry,
  }));
}

const buildOptionsGender = async(): Promise<SelectOption[]> => {
  return [
    ...await buildOptionsGenderPerson(),
    {value: GenderEnum.COMPANY, text: await translate('gender company')},
  ]
};

const buildOptionsGenderPerson = async(): Promise<SelectOption[]> => {
  return [
    {value: GenderEnum.MALE, text: await translate('gender male')},
    {value: GenderEnum.FEMALE, text: await translate('gender female')},
  ]
};

const buildOptionsNoRepairReason = (): Promise<SelectOption[]> => {
  const carRentalTranslationKeyPrefix = 'no repair reason';
  return buildOptionsFromEnum(NoRepairReasonEnum, carRentalTranslationKeyPrefix);
};

const buildOptionsCostUnitCutPositionType = async(): Promise<SelectOption[]> => {
  const carRentalTranslationKeyPrefix = 'cost unit cut position type';
  return buildOptionsFromEnum(CostUnitCutPositionTypesEnum, carRentalTranslationKeyPrefix);
};

const buildOptionsDateRangeGraphicAnalytics = async(): Promise<SelectOption[]> => {
  const optionValues = [
    DateRangeTypeEnum.LAST_12_MONTHS_RELATIVE,
    DateRangeTypeEnum.LAST_24_MONTHS_RELATIVE,
    DateRangeTypeEnum.UNTIL_TODAY_RELATIVE,
    DateRangeTypeEnum.MANUAL,
  ];
  const options: SelectOption[] = [];
  for (const value of optionValues) {
    options.push({value, text: await translate('date range type ' + value)})
  }
  return options;
}

const buildOptionsFromEnum = async(enumeration: any, translationKeyPrefix: string): Promise<SelectOption[]> => {
  const options: SelectOption[] = [];
  const values = Object.values(enumeration);
  for (const value of values) {
    if (typeof value !== 'number' && typeof value !== 'string') {
      continue;
    }
    options.push({
      text: await translate(translationKeyPrefix + ' ' + value),
      value: value,
    });
  }
  return options;
};

const buildOptionsOptionalYesNo = async(inversed: boolean): Promise<SelectOption[]> => {
  const options = await buildOptionsYesNo(inversed);
  options.push({text: await translate('optional boolean void'), value: 'void'});
  return options;
}

const buildOptionsYesNo = async(inversed: boolean): Promise<SelectOption[]> => {
  const yesLabel = inversed ? 'no' : 'yes';
  const noLabel = inversed ? 'yes' : 'no';

  let yesNoOptions = [
    {text: await translate('optional boolean ' + yesLabel), value: 'yes'},
    {text: await translate('optional boolean ' + noLabel), value: 'no'},
  ];

  if (inversed) {
    yesNoOptions = yesNoOptions.reverse();
  }

  return yesNoOptions;
}

const convertToOptionsFromCollection = (collection: ItemDataSkeletton[]): SelectOption[] => {
  const options: SelectOption[] = [];
  collection.forEach((optionData) => {
    options.push({
      value: optionData.attributes.value,
      text: optionData.attributes.text,
    })
  });
  return options;
}

const prependEmptyOption = async(
  options: SelectOption[],
  emptyValue: string | number | null = null,
  translationKey: string | null = null
): Promise<SelectOption[]> => {
  options.unshift({
    text: '<' + await translate(translationKey ? translationKey : 'select default option') + '>',
    value: emptyValue,
  });
  return options;
}

const translate = async(key: string): Promise<string> => {
  const translator = Container.getInstance().getTranslator();
  return await translator.trans(key) + '';
};

export {
  buildOptionsCarRentalSource,
  buildOptionsEstimatedDamage,
  buildOptionsGender,
  buildOptionsGenderPerson,
  buildOptionsCostUnitCutPositionType,
  buildOptionsDateRangeGraphicAnalytics,
  buildOptionsNoRepairReason,
  buildOptionsOptionalYesNo,
  buildOptionsYesNo,
  convertToOptionsFromCollection,
  prependEmptyOption
}
