var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    _vm._b(
      {
        attrs: {
          "invalid-feedback": _vm.invalidFeedback,
          label: _vm.label,
          "label-for": _vm.formControlId,
        },
      },
      "b-form-group",
      _vm.$attrs,
      false
    ),
    [
      _c("csp-form-select-searchable", {
        attrs: {
          id: _vm.formControlId,
          "allow-empty": !_vm.isRequiredProperty,
          disabled: _vm.isReadOnlyProperty,
          options: _vm.options,
          placeholder: _vm.placeholder,
          state: _vm.validationState,
          "value-empty": _vm.valueEmpty,
        },
        on: { change: _vm.handleInput },
        model: {
          value: _vm.data[_vm.property],
          callback: function ($$v) {
            _vm.$set(_vm.data, _vm.property, $$v)
          },
          expression: "data[property]",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }