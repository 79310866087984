import Vue from 'vue';
import components from './app/components';
import {ToastPlugin} from 'bootstrap-vue/src';
import Container from '@services/Container';
import VueFactory from '@services/VueFactory';
import VueCompositionAPI from '@vue/composition-api'

Vue.use(ToastPlugin);
Vue.use(VueCompositionAPI);

for (let componentsKey in components) {
    Vue.component(componentsKey, components[componentsKey]);
}

jQuery(() => {
    const container = Container.getInstance();
    const vueFactory = new VueFactory();
    const i18n = vueFactory.initializeI18n();
    container.getTranslator().setVueI18n(i18n);

    container.getErrorHandler().initialize();
    container.getLoadingState().initialize();

    container.getTranslationRepository().loadTranslation().then((result) => {
        const attributes = result.data.attributes;
        i18n.setLocaleMessage(attributes.locale, attributes.messages);
        i18n.locale = attributes.locale;
        container.getEventBus().emit('translations-loaded');
    });

    window.cspVueMain = new Vue({
        'el': '#layout',
        i18n,
        data: {
            loadingState: container.getLoadingState().state,
            multiselectOptionHeight: 32,
            signState: container.getSignRepository().state,
            translationKeySuffix: ' desktop',
            useLegacyPdfViewer: null,
        },
        methods: vueFactory.getGlobalMethods(),
    });
});
