<script>
    import Container from '@services/Container';
    const eventBus = Container.getInstance().getEventBus();

    export default {
        data() {
            return {
                popoverVisible: false,
                popoverTimeoutHandle: null,
                wrapperRef: null,
            }
        },
        computed: {
            isPopoverEnabled() {
                return this.popoverEnabled && this.popoverPlacement && this.wrapperRef;
            },
        },
        mounted() {
            this.wrapperRef = this.$refs.wrapper;
            document.body.addEventListener('click', this.handleBodyClickEvent);
            eventBus.on('statePopover:beforeOpen', this.handlePopoverBeforeOpen);
        },
        beforeDestroy() {
            document.body.removeEventListener('click', this.handleBodyClickEvent);
            eventBus.off('statePopover:beforeOpen', this.handlePopoverBeforeOpen);
        },
        methods: {
            togglePopover() {
                clearTimeout(this.popoverTimeoutHandle);
                if (!this.popoverVisible) {
                    eventBus.emit('statePopover:beforeOpen');
                }
                this.popoverVisible = !this.popoverVisible;
                if (this.popoverVisible) {
                    this.popoverTimeoutHandle = setTimeout(this.closePopover, 4000);
                }
            },
            closePopover() {
                this.popoverVisible = false;
            },
            handleBodyClickEvent(clickEvent) {
                if (clickEvent.target.classList.contains('popover-header')
                    || clickEvent.target.classList.contains('popover-body')
                    || clickEvent.target.parentElement.classList.contains('popover-body')
                ) {
                    this.popoverVisible = false;
                }
            },
            handlePopoverBeforeOpen() {
                this.closePopover();
            },
        },
    }
</script>
