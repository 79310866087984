<template>
    <component :is="htmlTag" v-bind="linkAttributes"
        :class="getClasses" :title="stateData.infoText" @click="handleClick">
        <span v-if="!stateData.icon">{{ label }}</span>
        <i v-if="stateData.icon" :class="stateData.icon"/>
    </component>
</template>

<script>
    export default {
        props: {
            stateData: {
                type: Object,
                required: true,
            },
        },
        emits: ['click'],
        computed: {
            htmlTag() {
                return this.stateData.linkUrl ? 'a' : 'div';
            },
            label() {
                if (this.stateData.icon) {
                    return ''
                }

                return this.stateData.badgeLabel;
            },
            linkAttributes() {
                if (!this.stateData.linkUrl) {
                    return {};
                }

                return {href: this.stateData.linkUrl};
            },
            getClasses() {
                var classes = ['record-state-badge'];

                classes.push(this.stateData.color);

                if (this.stateData.icon) {
                    classes.push('has-icon');
                }

                return classes;
            },
        },
        methods: {
            handleClick() {
                if (this.stateData.linkUrl) {
                    return;
                }

                this.$emit('click');
            },
        },
    }
</script>
