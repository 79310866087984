<template>
    <div id="messages-sidebar">
        <csp-messages-sidebar v-if="wasExpanded" :is-expanded="isExpanded"/>

        <div class="toggle-button" @click="toggleSidebar">
            <i class="inticon inticon-angle-left"/>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            expandOnLoad: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                layout: null,
                isExpanded: this.expandOnLoad,
                wasExpandedByButton: false,
            }
        },
        computed: {
            wasExpanded() {
                return this.expandOnLoad || this.wasExpandedByButton;
            },
        },
        mounted() {
            this.layout = document.getElementById('layout');

            if (this.expandOnLoad) {
                this.layout.classList.add('messages-sidebar-expanded');
            }
        },
        methods: {
            toggleSidebar() {
                this.isExpanded = !this.isExpanded;

                if (this.isExpanded) {
                    this.wasExpandedByButton = true;
                    this.layout.classList.add('messages-sidebar-expanded');
                    return;
                }

                this.layout.classList.remove('messages-sidebar-expanded');
            },
        },
    }
</script>
