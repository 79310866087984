export default class HttpError extends Error {
  private readonly statusCode: number;

  private readonly statusText: string;

  public constructor(statusText: string, statusCode: number) {
    super(statusText); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // Restore prototype chain
    this.statusText = statusText;
    this.statusCode = statusCode;
  }

  public getStatusCode(): number {
    return this.statusCode;
  }

  public getStatusText(): string {
    return this.statusText;
  }
}
