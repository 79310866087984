var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "csp-popover",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "tooltip",
            fn: function () {
              return [_vm._t("tooltip")]
            },
            proxy: true,
          },
          {
            key: "tooltipTitle",
            fn: function () {
              return [_vm._v("\n        Bemerkungen\n    ")]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [_vm._v(" "), _vm._v(" "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }