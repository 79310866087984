import Vue from 'vue';
import {RawLocation, Route} from 'vue-router';
import BeforeRouteChangeEvent from '@js/events/BeforeRouteChangeEvent';

export default class EventBus {
  private readonly vueEventBus: Vue = new Vue();

  public emit(event: string, ...args: any[]): void {
    this.vueEventBus.$emit(event, ...args);
  }

  public off(event: string | string[], callback: (...args: any) => any): void {
    this.vueEventBus.$off(event, callback);
  }

  public on(event: string | string[], callback: (...args: any) => any): void {
    this.vueEventBus.$on(event, callback);
  }

  public onRouterBeforeEach(component: Vue, callback: (event: BeforeRouteChangeEvent) => void): void {
    component.$router.beforeEach(
      (to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void) => {
        const event = new BeforeRouteChangeEvent(component.$router, to, from, next);
        callback(event);
      }
    );
  }

  public once(event: string, callback: (...args: any) => any): void {
    this.vueEventBus.$once(event, callback);
  }
}
