import AjaxService from '@services/AjaxService';

export default class UserRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public showCurrentUser(): Promise<any> {
    return this.ajaxService.getJson('/api/users/current');
  }
}
