<template>
    <b-form-group :invalid-feedback="invalidFeedback" :label="label" :label-for="formControlId">
        <b-form-textarea :id="formControlId"
            :class="potentiallyRequired ? 'border-yellow' : ''"
            :disabled="isReadOnlyProperty"
            :max="max" :max-rows="maxRows"
            :name="formControlId" :required="isRequiredProperty" :rows="rows"
            :state="validationState"
            :type="type"
            :value="data[property]"
            @input="handleInput"/>
    </b-form-group>
</template>

<script>
    import FormGroupMixin from './FormGroupMixin';

    export default {
        mixins: [FormGroupMixin],
        props: {
            type: {
                type: String,
                default: 'text',
            },
            max: {
                type: Number,
                default: null,
            },
            nullIfEmpty: {
                type: Boolean,
                default: false,
            },
            rows: {
                type: Number,
                default: 3,
            },
            maxRows: {
                type: Number,
                default: 3,
            },
        },
        methods: {
            handleInput(input) {
                if (this.nullIfEmpty && !input) {
                    input = null;
                }
                this.data[this.property] = input;
            },
        },
    }
</script>
