var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    _vm._g(
      {
        class: _vm.classes,
        style: _vm.dragStyle,
        on: {
          click: _vm.handleRowClick,
          mouseup: function ($event) {
            if ("button" in $event && $event.button !== 1) return null
            $event.preventDefault()
            return _vm.handleRowMiddleClick.apply(null, arguments)
          },
        },
      },
      _vm.dragDetectionListeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }