<template>
    <div class="hide-for-group-component">
    <div v-if="isLoading > 0" class="loading-overlay d-flex align-items-center justify-content-center">
        <csp-loading-indicator/>
    </div>
    <csp-form-group-checkbox-group
        :buttons="true"
        :form="form"
        :options="this.hideForGroupOptions"
        :property="property"
        @change="emitChange"/>
    </div>
</template>

<script>
import '@scss/components/hide-for-group.scss';
import Container from '@services/Container';

const recordRepository = Container.getInstance().getRecordRepository();

export default {
    props: {
        form: {
            type: Object,
            required: true,
        },
        property: {
            type: String,
            required: true,
        },
        recordId: {
            type: Number,
            required: true,
        },
    },
    emits: ['change'],
    data() {
        return {
            hideForGroupOptions: [],
            isLoading: 0,
        }
    },
    mounted() {
        this.isLoading = 0;
        this.loadHideForGroupOptions();
        this.loadHideForGroupDefault();
    },
    methods: {
        emitChange(value) {
            this.$emit('change', value);
        },
        loadHideForGroupOptions() {
            this.isLoading++;
            recordRepository.hideForGroupOptions(this.recordId).then(
                (options) => {
                    this.hideForGroupOptions = options;
                }
            ).finally(() => {
                this.isLoading--;
            });
        },
        loadHideForGroupDefault() {
            recordRepository.hideForGroupDefault(this.recordId).then(
                (success) => {
                    this.form.setProperty(this.property, success.data.attributes);
                }
            ).finally(() => {
                this.isLoading--;
            });
        },
    },
}
</script>
