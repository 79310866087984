import {BvToast} from 'bootstrap-vue/src/components/toast'
import FlashMessageTypeEnum from '@js/enums/FlashMessageTypeEnum';
import {VNode, VNodeChildren} from 'vue/types/vnode';
import PropertyErrorsInterface from '@form/validation/PropertyErrorsInterface';
import {CreateElement} from 'vue/types/vue';

export default class FlashMessenger {
  private toaster: BvToast | null = null;

  public addError(message: string): void {
    this.add(message, FlashMessageTypeEnum.ERROR, true);
  }

  public addErrorsAsWarning(errors: PropertyErrorsInterface[], createElement: CreateElement): void {
    const messages: VNodeChildren = [];
    errors.forEach((error) => {
      messages.push(error.title)
      messages.push(createElement('br'))
    });
    this.add(createElement('span', {}, messages), FlashMessageTypeEnum.WARNING, false);
  }

  public addFromResponse(flashMessage: any): void {
    this.add(flashMessage.message, flashMessage.type, flashMessage.sticky);
  }

  public addInfo(message: string): void {
    this.add(message, FlashMessageTypeEnum.INFO, false);
  }

  public addLegacyUploadErrorsAsWarning(
    errorMessages: Record<string, Record<string, string>>,
    createElement: CreateElement
  ): void {
    const messages: VNodeChildren = [];
    for (const elementName of Object.values(errorMessages)) {
      for (const message of Object.values(elementName)) {
        messages.push(message)
        messages.push(createElement('br'))
      }
    }
    this.add(createElement('span', {}, messages), FlashMessageTypeEnum.WARNING, false);
  }

  public addSuccess(message: string): void {
    this.add(message, FlashMessageTypeEnum.SUCCESS, false);
  }

  public addWarning(message: string): void {
    this.add(message, FlashMessageTypeEnum.WARNING, false);
  }

  public setToaster(toaster: BvToast): void {
    this.toaster = toaster;
  }

  private add(message: string | VNode, type: FlashMessageTypeEnum, sticky: boolean): void {
    if (!this.toaster) {
      return;
    }
    this.toaster.toast(
      message,
      {
        variant: type,
        noAutoHide: sticky,
      }
    );
  }
}
