import Vue from 'vue';
import ItemDataSkeletton from '@js/jsonapi/skeletton/ItemDataSkeletton';
import CollectionSkeletton from '@js/jsonapi/skeletton/CollectionSkeletton';
import FirmCategoryEnum from '@js/enums/FirmCategoryEnum';

interface PermissionsInterface {
  useVinRequests: boolean
}

export default class LoginStatusStore {
  public store = new Vue({
    data: {
      isLoggedIn: false,
      isInitialized: false,
      currentFirmData: new ItemDataSkeletton(),
      currentMetaData: new ItemDataSkeletton(),
      currentPopupsData: new CollectionSkeletton(),
      currentUserData: new ItemDataSkeletton(),
    },
  });

  public canUseVinRequests(): boolean {
    return this.getPermissions().useVinRequests;
  }

  public clearState(): void {
    this.store.$data.isInitialized = false;
    this.store.$data.isLoggedIn = false;
    this.resetData();
  }

  public getCurrentFirmAttributes(): any {
    return this.store.$data.currentFirmData.attributes;
  }

  public getCurrentFirmId(): string {
    return this.store.$data.currentFirmData.id;
  }

  public getCurrentMetaData(): any {
    return this.store.$data.currentMetaData;
  }

  public getCurrentPopups(): ItemDataSkeletton[] {
    return this.store.$data.currentPopupsData.data;
  }

  public getCurrentUserAttributes(): any {
    return this.store.$data.currentUserData.attributes;
  }

  public getRemoteAccessType(): string {
    if (!this.isLoggedIn()) {
      return '';
    }
    return this.store.$data.currentUserData.attributes.remoteAccessType;
  }

  public getResellerId(): number {
    return this.store.$data.currentFirmData.attributes.resellerId;
  }

  public isAdmin(): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    return this.store.$data.currentUserData.attributes.isAdmin;
  }

  public isCarDealer(): boolean {
    return this.getCurrentFirmAttributes().firmCategoryId === FirmCategoryEnum.CARDEALER;
  }

  public isLoggedIn(): boolean {
    return this.store.$data.isInitialized && this.store.$data.isLoggedIn;
  }

  public isLoggedOut(): boolean {
    return this.store.$data.isInitialized && !this.store.$data.isLoggedIn;
  }

  public removePendingPopup(): void {
    this.store.$data.currentPopupsData.data.shift();
  }

  public setCurrentUser(currentUserData: any): void {
    if (!currentUserData.data) {
      this.setUserIsLoggedOut();
      return;
    }

    this.store.$data.isInitialized = true;
    this.store.$data.isLoggedIn = true;
    this.store.$data.currentFirmData = currentUserData.data.attributes.firm.data;
    this.store.$data.currentMetaData = currentUserData.data.meta;
    this.store.$data.currentPopupsData.data = currentUserData.data.attributes.popups.data || [];
    this.store.$data.currentUserData = currentUserData.data;
  }

  private getPermissions(): PermissionsInterface {
    return this.store.$data.currentMetaData.permissions;
  }

  private resetData(): void {
    this.store.$data.currentFirmData = new ItemDataSkeletton();
    this.store.$data.currentMetaData = {useVinRequests: false};
    this.store.$data.currentPopupsData = new CollectionSkeletton();
    this.store.$data.currentUserData = new ItemDataSkeletton();
  }

  private setUserIsLoggedOut(): void {
    this.store.$data.isInitialized = true;
    this.store.$data.isLoggedIn = false;
    this.resetData();
  }
}
