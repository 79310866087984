import PropertyErrorsInterface from '@form/validation/PropertyErrorsInterface';

export default class ValidationError extends Error {
  private readonly propertyErrors: PropertyErrorsInterface[];

  public constructor(message: string, propertyErrors: PropertyErrorsInterface[]) {
    super(message); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // Restore prototype chain
    this.name = 'ValidationError';
    this.propertyErrors = propertyErrors;
  }

  public getPropertyErrors(): PropertyErrorsInterface[] {
    return this.propertyErrors;
  }
}
