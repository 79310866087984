import AjaxService from '@services/AjaxService';

export default class InvoicingModalityRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public deleteFirmModalities(firmId: number): Promise<any> {
    const url = `/api/firms/${firmId}/invoicingModalities/data`
    return this.ajaxService.sendDelete(url);
  }

  public showFirmData(firmId: number): Promise<any> {
    const url = `/api/firms/${firmId}/invoicingModalities/data`;
    return this.ajaxService.getJson(url);
  }

  public showFirmGroupData(firmGroupId: number): Promise<any> {
    const url = `/api/firmGroups/${firmGroupId}/invoicingModalities/data`;
    return this.ajaxService.getJson(url);
  }

  public showFirmGroupInvoiceRecipientOptions(firmGroupId: number): Promise<any> {
    const url = `/api/firmGroups/${firmGroupId}/invoicingModalities/invoiceRecipientOptions`;
    return this.ajaxService.getJson(url);
  }

  public showFirmGroupStructure(firmGroupId: number): Promise<any> {
    const url = `/api/firmGroups/${firmGroupId}/invoicingModalities/structure`;
    return this.ajaxService.getJson(url);
  }

  public showFirmInvoiceRecipientOptions(firmId: number): Promise<any> {
    const url = `/api/firms/${firmId}/invoicingModalities/invoiceRecipientOptions`;
    return this.ajaxService.getJson(url);
  }

  public showFirmStructure(firmId: number): Promise<any> {
    const url = `/api/firms/${firmId}/invoicingModalities/structure`;
    return this.ajaxService.getJson(url);
  }

  public updateFirm(firmId: number, attributes: any): Promise<any> {
    const url = `/api/firms/${firmId}/invoicingModalities/data`;
    return this.ajaxService.putJson(url, {data: {attributes: attributes}});
  }

  public updateFirmGroup(firmGroupId: number, attributes: any): Promise<any> {
    const url = `/api/firmGroups/${firmGroupId}/invoicingModalities/data`;
    return this.ajaxService.putJson(url, {data: {attributes: attributes}});
  }
}
