import VueI18n from 'vue-i18n';
import EventBus from '@services/EventBus';

export default class Translator {
  private translationsLoaded = false;

  private vueI18n?: VueI18n;

  private waitingTranslations: { (): void; }[] = [];

  public constructor(eventBus: EventBus) {
    eventBus.on('translations-loaded', () => {
      this.translationsLoaded = true;
      this.resolveWaitingTranslations();
    })
  }

  public setVueI18n(vueI18n: VueI18n): void {
    this.vueI18n = vueI18n;
    this.resolveWaitingTranslations();
  }

  public trans(key: string): Promise<string> {
    return new Promise<string>((resolve) => {
      const resolveCall = () => {
        if (!this.vueI18n) {
          throw 'vueI18n not initialized!';
        }
        resolve(this.vueI18n.t(key) + '');
      }

      if (this.areTranslationsResolvable()) {
        resolveCall();
        return;
      }

      this.waitingTranslations.push(resolveCall);
    });
  }

  private areTranslationsResolvable(): boolean {
    if (!this.vueI18n) {
      return false;
    }
    return this.translationsLoaded;
  }

  private resolveWaitingTranslations(): void {
    if (!this.areTranslationsResolvable()) {
      return;
    }
    for (const resolve of this.waitingTranslations) {
      resolve();
    }
    this.waitingTranslations = [];
  }
}
