import AjaxService from '@services/AjaxService';
import Form from '@form/Form';

export default class CostUnitRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public index(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/costunits';
    return this.ajaxService.getJson(url);
  }

  public show(recordId: number, costUnitId: number): Promise<any> {
    const url = `/api/records/${recordId}/costunits/${costUnitId}`;
    return this.ajaxService.getJson(url);
  }

  public showWithCutPositions(recordId: number, costUnitId: number): Promise<any> {
    const url = `/api/records/${recordId}/costunits/${costUnitId}?include[]=cutPositions`;
    return this.ajaxService.getJson(url);
  }

  public updateCutPositions(recordId: number, costUnitId: number, form: Form): Promise<any> {
    const url = `/api/records/${recordId}/costunits/${costUnitId}/cutpositions`;
    return this.ajaxService.putFormData(url, form);
  }
}
