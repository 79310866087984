/** Defines a base structure for items that are usually part of collections. */
export default class ItemDataSkeletton {
  public attributes: any;

  public id: string;

  public links: any;

  public meta: any;

  public constructor() {
    this.attributes = {};
    this.id = '';
    this.links = {};
    this.meta = {
      permissions: {},
    };
  }
}
