var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "record-state-overview-wrapper" },
    [
      _c(
        "div",
        {
          ref: "wrapper",
          class: _vm.wrapperClasses,
          on: { click: _vm.handleClick },
        },
        _vm._l(_vm.stateDataItems, function (stateData, index) {
          return _c("csp-record-state-badge", {
            key: index,
            class: _vm.isPopoverEnabled ? "skip-row-click clickable" : "",
            attrs: { "state-data": stateData },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.isPopoverEnabled
        ? _c(
            "b-popover",
            {
              ref: "popover",
              attrs: {
                "custom-class": "clickable",
                placement: _vm.popoverPlacement,
                show: _vm.popoverVisible,
                target: _vm.wrapperRef,
                triggers: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v("\n            Status\n        ")]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2045762601
              ),
            },
            [
              _vm._v(" "),
              _vm._l(_vm.stateDataItems, function (stateData, index) {
                return _c("p", { key: index, staticClass: "mb-1" }, [
                  _vm._v(
                    "\n            " + _vm._s(stateData.infoText) + "\n        "
                  ),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showTextInfo
        ? _c("div", { staticClass: "record-state-info-text" }, [
            _c(
              "ul",
              _vm._l(_vm.stateDataItems, function (stateData, index) {
                return _c("li", { key: index }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(stateData.infoText) +
                      "\n            "
                  ),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }