enum CostUnitCutPositionTypesEnum {
  ADDITIONAL_PAINTING = 'additional_painting',
  CLEANING_DESINFECTION = 'cleaning_desinfection',
  OTHER = 'other',
  SHIPMENT = 'shipment',
  SMALL_SPARE_PARTS = 'small_spare_parts',
  TEST_DRIVE = 'test_drive',
  UPE_SURCHARGES = 'upe_surcharges'
}

export default CostUnitCutPositionTypesEnum;
