import AjaxService from '@services/AjaxService';
import AnalyticRepository from '@repositories/damage/AnalyticRepository';
import ApplicationRepository from '@repositories/ApplicationRepository';
import AuthRepository from '@repositories/AuthRepository';
import CostUnitRepository from '@repositories/damage/record/CostUnitRepository';
import CostUnitStatisticsRepository from '@repositories/damage/record/CostUnitStatisticsRepository';
import PotentialCheckRepository from '@repositories/damage/record/PotentialCheckRepository';
import ErrorRepository from '@repositories/ErrorRepository';
import EventBus from '@services/EventBus';
import FileCategoryGroupRepository from '@repositories/file/FileCategoryGroupRepository';
import FileCategoryRepository from '@repositories/file/FileCategoryRepository';
import FirmLevelRepository from '@repositories/FirmLevelRepository';
import FirmRepository from '@repositories/FirmRepository';
import FlashMessenger from '@services/FlashMessenger';
import InsuranceRepository from '@repositories/damage/InsuranceRepository';
import LoginStatusStore from '@services/LoginStatusStore';
import MessageRepository from '@repositories/MessageRepository';
import NoteCalendarRepository from '@repositories/damage/NoteCalendarRepository';
import RecordRepository from '@repositories/damage/RecordRepository';
import SupportRepository from '@repositories/SupportRepository';
import TranslationRepository from '@repositories/TranslationRepository';
import UserPreferenceRepository from '@repositories/UserPreferenceRepository';
import UserRepository from '@repositories/UserRepository';
import ErrorHandler from '../../csp-app/lib/ErrorHandler';
import LoadingState from '@services/loading/LoadingState';
import ReloadPreventer from '@services/loading/ReloadPreventer';
import SignRepository from '@repositories/damage/SignRepository';
import QuestionnaireResponseRepository from '@repositories/QuestionnaireResponseRepository';
import Translator from '@services/Translator';
import ExternalServiceRepository from '@repositories/damage/record/ExternalServiceRepository';
import InvoicingModalityRepository from '@repositories/InvoicingModalityRepository';
import ReceiptRepository from '@repositories/damage/ReceiptRepository';
import VehicleManufacturerRepository from '@repositories/VehicleManufacturerRepository';

interface ConstructorMethod {
  (): any;
}

export default class Container {
  private static containerInstance: Container = new Container();

  private instances: any = {};

  public constructor() {
    if (Container.containerInstance) {
      throw new Error('Error: Instantiation failed: Use SingletonDemo.getInstance() instead of new.');
    }
    Container.containerInstance = this;
  }

  public static getInstance(): Container {
    return Container.containerInstance;
  }

  public getAjaxService(): AjaxService {
    return this.getSingleton(
      'AjaxService',
      () => {
        return new AjaxService(
          this.getEventBus()
        );
      }
    );
  }

  public getAnalyticRepository(): AnalyticRepository {
    return this.getSingleton(
      'AnalyticRepository',
      () => {
        return new AnalyticRepository(this.getAjaxService());
      }
    );
  }

  public getApplicationRepository(): ApplicationRepository {
    return this.getSingleton(
      'ApplicationRepository',
      () => {
        return new ApplicationRepository(this.getAjaxService());
      }
    );
  }

  public getAuthRepository(): AuthRepository {
    return this.getSingleton(
      'AuthRepository',
      () => {
        return new AuthRepository(this.getAjaxService());
      }
    );
  }

  public getCostUnitRepository(): CostUnitRepository {
    return this.getSingleton(
      'QuestionnaireResponseRepository', () => {
        return new CostUnitRepository(this.getAjaxService());
      }
    );
  }

  public getCostUnitStatisticRepository(): CostUnitStatisticsRepository {
    return this.getSingleton(
      'CostUnitStatisticRepository',
      () => {
        return new CostUnitStatisticsRepository(this.getAjaxService());
      }
    );
  }

  public getErrorHandler(): ErrorHandler {
    return this.getSingleton(
      'ErrorHandler',
      () => {
        return new ErrorHandler(this.getErrorRepository(), this.getEventBus(), this.getFlashMessenger());
      }
    );
  }

  public getErrorRepository(): ErrorRepository {
    return this.getSingleton(
      'ErrorRepostiroy',
      () => {
        return new ErrorRepository(this.getAjaxService());
      }
    );
  }

  public getEventBus(): EventBus {
    return this.getSingleton(
      'EventBus',
      () => {
        return new EventBus();
      }
    );
  }

  public getExternalServiceRepository(): ExternalServiceRepository {
    return this.getSingleton(
      'ExternalServiceRepository',
      () => {
        return new ExternalServiceRepository(this.getAjaxService());
      }
    )
  }

  public getFileCategoryGroupRepository(): FileCategoryGroupRepository {
    return this.getSingleton(
      'fileCategoryGroupRepository', () => {
        return new FileCategoryGroupRepository(this.getAjaxService());
      }
    )
  }

  public getFileCategoryRepository(): FileCategoryRepository {
    return this.getSingleton(
      'fileCategoryRepository', () => {
        return new FileCategoryRepository(this.getAjaxService());
      }
    )
  }

  public getFirmLevelRepository(): FirmLevelRepository {
    return this.getSingleton(
      'FirmLevelRepository',
      () => {
        return new FirmLevelRepository(this.getAjaxService());
      }
    )
  }

  public getFirmRepository(): FirmRepository {
    return this.getSingleton(
      'FirmRepository',
      () => {
        return new FirmRepository(
          this.getAjaxService(),
          this.getLoginStatusStore()
        );
      }
    );
  }

  public getFlashMessenger(): FlashMessenger {
    return this.getSingleton(
      'FlashMessenger',
      () => {
        return new FlashMessenger();
      }
    );
  }

  public getInsuranceRepository(): InsuranceRepository {
    return this.getSingleton(
      'InsuranceRepository', () => {
        return new InsuranceRepository(this.getAjaxService())
      }
    );
  }

  public getInvoicingModalityRepository(): InvoicingModalityRepository {
    return this.getSingleton(
      'InvoicingModalityRepository', () => {
        return new InvoicingModalityRepository(this.getAjaxService())
      }
    );
  }

  public getLoadingState(): LoadingState {
    return this.getSingleton(
      'LoadingState',
      () => {
        return new LoadingState(this.getEventBus());
      }
    );
  }

  public getLoginStatusStore(): LoginStatusStore {
    return this.getSingleton(
      'LoginStatusStore',
      () => {
        return new LoginStatusStore();
      }
    );
  }

  public getMessageRepository(): MessageRepository {
    return this.getSingleton(
      'MessageRepository',
      () => {
        return new MessageRepository(this.getAjaxService());
      }
    );
  }

  public getNoteCalendarRepository(): NoteCalendarRepository {
    return this.getSingleton(
      'NoteCalendarRepository',
      () => {
        return new NoteCalendarRepository(this.getAjaxService());
      }
    );
  }

  public getPotentialCheckRepository(): PotentialCheckRepository {
    return this.getSingleton(
      'PotentialCheckRepository', () => {
        return new PotentialCheckRepository(this.getAjaxService());
      }
    );
  }

  public getQuestionnaireResponseRepository(): QuestionnaireResponseRepository {
    return this.getSingleton(
      'QuestionnaireResponseRepository', () => {
        return new QuestionnaireResponseRepository(this.getAjaxService());
      }
    );
  }

  public getReceiptRepository(): ReceiptRepository {
    return this.getSingleton(
      'ReceiptRepository', () => {
        return new ReceiptRepository(this.getAjaxService());
      }
    );
  }

  public getRecordRepository(): RecordRepository {
    return this.getSingleton(
      'RecordRepository', () => {
        return new RecordRepository(this.getAjaxService(), this.getFirmRepository())
      }
    );
  }

  public getReloadPreventer(): ReloadPreventer {
    return this.getSingleton(
      'ReloadPreventer',
      () => {
        return new ReloadPreventer(this.getLoadingState());
      }
    );
  }

  public getSignRepository(): SignRepository {
    return this.getSingleton(
      'SignRepository', () => {
        return new SignRepository(
          this.getAjaxService(),
          this.getEventBus(),
          this.getFlashMessenger()
        )
      }
    );
  }

  public getSupportRepository(): SupportRepository {
    return this.getSingleton(
      'SupportRepository',
      () => {
        return new SupportRepository(
          this.getAjaxService()
        );
      }
    );
  }

  public getTranslationRepository(): TranslationRepository {
    return this.getSingleton(
      'TranslationRepository',
      () => {
        return new TranslationRepository(
          this.getAjaxService(),
          this.getEventBus()
        );
      }
    );
  }

  public getTranslator(): Translator {
    return this.getSingleton(
      'Translator',
      () => {
        return new Translator(this.getEventBus());
      }
    );
  }

  public getUserPreferenceRepository(): UserPreferenceRepository {
    return this.getSingleton(
      'UserPreferenceRepository',
      () => {
        return new UserPreferenceRepository(
          this.getAjaxService()
        );
      }
    );
  }

  public getUserRepository(): UserRepository {
    return this.getSingleton(
      'UserRepository',
      () => {
        return new UserRepository(
          this.getAjaxService()
        );
      }
    );
  }

  public getVehicleManufacturerRepository(): VehicleManufacturerRepository {
    return this.getSingleton(
      'VehicleManufacturerRepository',
      () => {
        return new VehicleManufacturerRepository(
          this.getAjaxService()
        );
      }
    );
  }

  private getSingleton(identifier: string, constructor: ConstructorMethod): any {
    if (Object.prototype.hasOwnProperty.call(this.instances, identifier)) {
      return this.instances[identifier];
    }
    this.instances[identifier] = constructor();
    return this.instances[identifier];
  }
}
