var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.formGroupClasses }, [
    _c(
      "button",
      {
        staticClass: "btn btn-primary btn-action",
        attrs: { disabled: _vm.isDisabled, type: "submit" },
        on: { click: (e) => _vm.$emit("click", e) },
      },
      [
        _vm._t("default"),
        _vm._v(" "),
        _vm.isLoading
          ? _c("span", {
              staticClass: "spinner-border spinner-border-sm",
              attrs: { "aria-hidden": "true", role: "status" },
            })
          : _vm._e(),
        _vm._v("\n        " + _vm._s(_vm.label) + "\n    "),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }