var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hide-for-group-component" },
    [
      _vm.isLoading > 0
        ? _c(
            "div",
            {
              staticClass:
                "loading-overlay d-flex align-items-center justify-content-center",
            },
            [_c("csp-loading-indicator")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("csp-form-group-checkbox-group", {
        attrs: {
          buttons: true,
          form: _vm.form,
          options: this.hideForGroupOptions,
          property: _vm.property,
        },
        on: { change: _vm.emitChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }