var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    _vm._b(
      {
        attrs: {
          "invalid-feedback": _vm.invalidFeedback,
          label: _vm.label,
          "label-for": _vm.formControlId,
        },
      },
      "b-form-group",
      _vm.formGroupProps,
      false
    ),
    [
      _c(
        "b-form-radio-group",
        {
          class: [
            _vm.radioGroupClasses,
            _vm.potentiallyRequired ? "border-yellow" : "",
          ],
          attrs: {
            id: _vm.formControlId,
            disabled: _vm.isDisabled,
            name: _vm.formControlId,
            options: _vm.options,
            required: _vm.isRequiredProperty,
            state: _vm.validationState,
          },
          on: {
            change: function ($event) {
              return _vm.$emit("change")
            },
          },
          model: {
            value: _vm.data[_vm.property],
            callback: function ($$v) {
              _vm.$set(_vm.data, _vm.property, $$v)
            },
            expression: "data[property]",
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }