import EventBus from '@services/EventBus';

export default class LoadingState {
  public static readonly EVENT_LOADING_END = 'loading:end';

  public static readonly EVENT_LOADING_START = 'loading:start';

  public state = {isLoading: false};

  private readonly eventBus: EventBus;

  private loadingCounter = 0;

  public constructor(eventBus: EventBus) {
    this.eventBus = eventBus;
  }

  public initialize(): void {
    this.eventBus.on(LoadingState.EVENT_LOADING_START, () => this.increaseLoadingCount());
    this.eventBus.on(LoadingState.EVENT_LOADING_END, () => this.decreaseLoadingCount());
  }

  public isLoading(): boolean {
    return this.state.isLoading;
  }

  private decreaseLoadingCount(): void {
    this.loadingCounter--;
    this.updateLoadingState();
  }

  private increaseLoadingCount(): void {
    this.loadingCounter++;
    this.updateLoadingState();
  }

  private updateLoadingState(): void {
    this.state.isLoading = this.loadingCounter > 0;
  }
}
