import AjaxService from '@services/AjaxService';

export default class ExternalServiceRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public load(): Promise<any> {
    const url = '/api/externalService/load';
    return this.ajaxService.getJson(url);
  }

  public save(data: any): Promise<any> {
    const url = '/api/externalService/save';
    return this.ajaxService.postJson(url, data);
  }

  public validate(data: any): Promise<any> {
    const url = '/api/externalService/validate';
    return this.ajaxService.postJson(url, data);
  }
}
