<template>
    <div :class="formGroupClasses">
        <button class="btn btn-primary btn-action" :disabled="isDisabled" type="submit"
            @click="(e) => $emit('click', e)">
            <slot/>
            <span v-if="isLoading" aria-hidden="true"
                class="spinner-border spinner-border-sm" role="status"/>
            {{ label }}
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            alignment: {
                type: String,
                default: 'right',
            },
            disable: {
                type: Boolean,
                default: false,
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: 'Speichern',
            },
        },
        emits: ['click'],
        computed: {
            formGroupClasses() {
                const classes = ['form-group'];
                if (this.alignment) {
                    classes.push('text-' + this.alignment);
                }
                return classes;
            },
            isDisabled() {
                return this.disable || this.isLoading;
            },
        },
    }
</script>
