<template>
    <b-form-group :invalid-feedback="invalidFeedback" :label="label" :label-for="formControlId">
        <b-form-checkbox :id="formControlId" v-model="data[property]" :button="button"
            :disabled="isReadOnlyProperty" :name="formControlId" :required="isRequiredProperty"
            :state="validationState" :switch="asSwitch" :unchecked-value="uncheckedValue" :value="value"
            @change="emitChange">
            <template v-if="!hasLabelSlot">
                {{ $t('form field checkbox ' + labelPropertyValue) }}
            </template>
            <slot name="label"/>
        </b-form-checkbox>
    </b-form-group>
</template>

<script>
    import FormGroupMixin from '@components/form/FormGroupMixin';

    export default {
        mixins: [FormGroupMixin],
        props: {
            asSwitch: {
                type: Boolean,
                defaults: false,
            },
            uncheckedValue: {
                type: [String, Boolean],
                default: false,
            },
            value: {
                type: [String, Boolean],
                default: true,
            },
            button: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['change'],
        computed: {
            hasLabelSlot() {
                return !!this.$slots.label;
            },
        },
        methods: {
            emitChange(value) {
                this.$emit('change', value);
            },
        },
    }
</script>
