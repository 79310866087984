import EventBus from '@services/EventBus';
import FlashMessenger from '@services/FlashMessenger';
import AjaxService from '@services/AjaxService';
import Form from '@form/Form';
import ItemDataSkeletton from '@js/jsonapi/skeletton/ItemDataSkeletton';

export default class SignRepository {
  public static readonly EVENT_SIGN_SUCCESS = 'recordDocuments:signSuccess';

  public state = {signInProgress: false};

  private readonly ajaxService: AjaxService;

  private readonly eventBus: EventBus;

  private readonly flashMessenger: FlashMessenger;

  public constructor(ajaxService: AjaxService, eventBus: EventBus, flashMessenger: FlashMessenger) {
    this.ajaxService = ajaxService;
    this.eventBus = eventBus;
    this.flashMessenger = flashMessenger;
  }

  public isSignInProgress(): boolean {
    return this.state.signInProgress;
  }

  public recordFileSignRequest(recordId: number, fileId: number, form: Form): Promise<any> {
    const url = '/api/records/' + recordId + '/files/' + fileId + '/signature-collection';
    return this.ajaxService.postFormData(url, form);
  }

  public remoteAccessBuildFileDownloadUrl(document: ItemDataSkeletton): string {
    return `/api/external/signature-collection/files/${document.attributes.previewFileId}`;
  }

  public remoteAccessShowCollection(): Promise<any> {
    const url = '/api/external/signature-collection';
    return this.ajaxService.getJson(url);
  }

  public remoteAccessSign(
    imageData: string[],
    filledDocuments: string[],
    additionalData: any
  ): Promise<any> {
    const url = '/api/external/signature-collection/sign';
    return this.signWithUrl(url, imageData, filledDocuments, additionalData);
  }

  public remoteAccessSignAbort(): Promise<any> {
    return this.ajaxService.sendDelete('/api/external/signature-collection');
  }

  public sign(recordId: number,
    signatureCollectionId: string,
    imageData: string[],
    filledDocuments: string[],
    additionalData: any
  ): Promise<any> {
    const url = '/api/records/' + recordId + '/documents/signature-collection/' + signatureCollectionId + '/sign';
    return this.signWithUrl(url, imageData, filledDocuments, additionalData);
  }

  public signAbort(recordId: number, signatureCollectionId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/documents/signature-collection/' + signatureCollectionId;
    return this.ajaxService.sendDelete(url);
  }

  public signRequest(recordId: number, form: Form): Promise<any> {
    const url = '/api/records/' + recordId + '/documents/signature-collection';
    return this.ajaxService.postFormData(url, form);
  }

  private signWithUrl(
    url: string,
    imageData: string[],
    filledDocuments: string[],
    additionalData: any
  ): Promise<any> {
    this.state.signInProgress = true;

    const postData: any = {data: {attributes: {...additionalData, imageData, filledDocuments}}};

    return this.ajaxService.postJson(url, postData)
      .then(
        (response) => {
          this.state.signInProgress = false;
          this.eventBus.emit(SignRepository.EVENT_SIGN_SUCCESS, response);
        },
        () => {
          this.state.signInProgress = false;
          this.flashMessenger.addError('Beim Unterschreiben ist ein Fehler aufgetreten.');
        }
      );
  }
}
