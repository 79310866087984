var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "invalid-feedback": _vm.invalidFeedback,
        label: _vm.label,
        "label-for": _vm.formControlId,
      },
    },
    [
      _c("b-form-textarea", {
        class: _vm.potentiallyRequired ? "border-yellow" : "",
        attrs: {
          id: _vm.formControlId,
          disabled: _vm.isReadOnlyProperty,
          max: _vm.max,
          "max-rows": _vm.maxRows,
          name: _vm.formControlId,
          required: _vm.isRequiredProperty,
          rows: _vm.rows,
          state: _vm.validationState,
          type: _vm.type,
          value: _vm.data[_vm.property],
        },
        on: { input: _vm.handleInput },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }