<template>
    <b-form-group v-bind="formGroupProps" :invalid-feedback="invalidFeedback" :label="label" :label-for="formControlId">
        <b-form-radio-group :id="formControlId" v-model="data[property]"
            :class="[radioGroupClasses, potentiallyRequired ? 'border-yellow' : '']"
            :disabled="isDisabled" :name="formControlId" :options="options" :required="isRequiredProperty"
            :state="validationState" @change="$emit('change')">
            <slot/>
        </b-form-radio-group>
    </b-form-group>
</template>

<script>
    import FormGroupMixin from '@components/form/FormGroupMixin';
    import {buildOptionsOptionalYesNo} from '@js/functions/form/select';

    export default {
        mixins: [FormGroupMixin],
        props: {
            alignHorizontal: {
                type: Boolean,
                default: false,
            },
            disable: {
                type: Boolean,
                default: false,
            },
            inversed: {
                type: Boolean,
                default: false,
            },
            labelCols: {
                type: Number,
                default: null,
            },
        },
        emits: ['change'],
        data() {
            return {
                options: [],
            }
        },
        computed: {
            formGroupProps() {
                const props = {};
                if (this.labelCols) {
                    props.labelCols = this.labelCols;
                }
                return props;
            },
            isDisabled() {
                return this.disable || this.isReadOnlyProperty;
            },
            radioGroupClasses() {
                if (!this.alignHorizontal) {
                    return '';
                }
                return 'pt-2';
            },
        },
        async mounted() {
            this.options = await buildOptionsOptionalYesNo(this.inversed);
        },
    }
</script>
