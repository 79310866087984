import MetaPaginationSkeletton from './MetaPaginationSkeletton';
import ItemDataSkeletton from '@js/jsonapi/skeletton/ItemDataSkeletton';

export default class CollectionSkeletton {
  public data: ItemDataSkeletton[];

  public links: any;

  public meta: any;

  public constructor() {
    this.data = [];
    this.links = {};
    this.meta = {
      permissions: {},
      pagination: new MetaPaginationSkeletton(),
    };
  }
}
