<script>
    import SelectSearchableMixin from '@components/form/SelectSearchableMixin';
    import EmptyValueMixin from '@components/form/mixins/EmptyValueMixin';

    export default {
        mixins: [EmptyValueMixin, SelectSearchableMixin],
        model: {
            prop: 'value',
            event: 'change',
        },
        props: {
            allowEmpty: {
                type: Boolean,
                default: false,
            },
            value: {
                type: [String, Number],
                default: '',
            },
        },
        emits: ['change'],
        data() {
            return {
                selectedOption: this.getSelectedOption(),
            };
        },
        computed: {
            customProps() {
                return {
                    'allow-empty': this.allowEmpty,
                    'data-value': this.value,
                    'value': this.selectedOption,
                };
            },
            deselectLabel() {
                if (!this.allowEmpty) {
                    return '';
                }
                return this.$t('form.multiselect.deselect');
            },
        },
        watch: {
            options() {
                this.loadSelectedOption();
                this.handleInput(this.selectedOption);
            },
            value(newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }
                this.loadSelectedOption();
            },
        },
        methods: {
            getSelectedOption() {
                let selectedOption = null;
                this.getFlatOptions(this.options).some((option) => {
                    if (option.value === this.value) {
                        selectedOption = option;
                        return true;
                    }
                    return false;
                });
                return selectedOption;
            },
            handleInput(selectedOption) {
                this.selectedOption = selectedOption;
                let value = this.valueEmpty;
                if (selectedOption) {
                    value = selectedOption.value;
                }
                this.$emit('change', value, selectedOption);
            },
            initializeSelected() {
                this.loadSelectedOption();
            },
            isOptionSelected(option) {
                return option.value === this.value;
            },
            loadSelectedOption() {
                this.selectedOption = this.getSelectedOption();
            },
        },
    }
</script>
