<template>
    <div>
        <ckeditor v-if="isLayoutReady" ref="editor" v-model="editorData" :config="config" :editor="editor"
            @blur="onEditorBlur"/>
    </div>
</template>

<script>
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    import {
        AccessibilityHelp,
        Alignment,
        AutoLink,
        Autosave,
        BlockQuote,
        Bold,
        ClassicEditor,
        Code,
        CodeBlock,
        Essentials,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        GeneralHtmlSupport,
        Heading,
        Highlight,
        HorizontalLine,
        Indent,
        IndentBlock,
        Italic,
        Link,
        List,
        Paragraph,
        PasteFromOffice,
        PastePlainText,
        RemoveFormat,
        SelectAll,
        SourceEditing,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Strikethrough,
        Style,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        Underline,
        Undo
    } from 'ckeditor5';

    import translations from 'ckeditor5/translations/de.js';

    import 'ckeditor5/ckeditor5.css';
    import '@scss/components/ckeditor.scss';

    export default {
        components: {
            ckeditor: CKEditor.component,
        },
        props: {
            textareaId: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                isLayoutReady: false,
                config: null, // CKEditor needs the DOM tree before calculating the configuration.
                editor: ClassicEditor,
                editorData: '',
            };
        },
        mounted() {
            this.config = {
                toolbar: {
                    items: [
                        'selectAll',
                        '|',
                        'heading',
                        '|',
                        'fontFamily',
                        'fontSize',
                        '|',
                        'undo',
                        'redo',
                        '|',
                        'alignment',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        '|',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'indent',
                        'outdent',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'removeFormat',
                        '|',
                        'sourceEditing',
                        '|',
                        'accessibilityHelp',
                    ],
                    shouldNotGroupWhenFull: true,
                },
                plugins: [
                    AccessibilityHelp,
                    Alignment,
                    AutoLink,
                    Autosave,
                    BlockQuote,
                    Bold,
                    Code,
                    CodeBlock,
                    Essentials,
                    FontBackgroundColor,
                    FontColor,
                    FontFamily,
                    FontSize,
                    GeneralHtmlSupport,
                    Heading,
                    Highlight,
                    HorizontalLine,
                    Indent,
                    IndentBlock,
                    Italic,
                    Link,
                    List,
                    Paragraph,
                    PasteFromOffice,
                    PastePlainText,
                    RemoveFormat,
                    SelectAll,
                    SourceEditing,
                    SpecialCharacters,
                    SpecialCharactersArrows,
                    SpecialCharactersCurrency,
                    SpecialCharactersEssentials,
                    SpecialCharactersLatin,
                    SpecialCharactersMathematical,
                    SpecialCharactersText,
                    Strikethrough,
                    Style,
                    Subscript,
                    Superscript,
                    Table,
                    TableCaption,
                    TableCellProperties,
                    TableColumnResize,
                    TableProperties,
                    TableToolbar,
                    Underline,
                    Undo,
                ],
                fontFamily: {
                    supportAllValues: true,
                },
                fontSize: {
                    options: [10, 12, 14, 'default', 18, 20, 22],
                    supportAllValues: true,
                },
                heading: {
                    options: [
                        {
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph',
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1',
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2',
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3',
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4',
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5',
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6',
                        },
                    ],
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /^.*$/,
                            styles: true,
                            attributes: true,
                            classes: true,
                        },
                    ],
                },
                language: 'de',
                link: {
                    addTargetToExternalLinks: true,
                    defaultProtocol: 'https://',
                    decorators: {
                        toggleDownloadable: {
                            mode: 'manual',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file',
                            },
                        },
                    },
                },
                placeholder: '',
                style: {
                    definitions: [
                        {
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category'],
                        },
                        {
                            name: 'Title',
                            element: 'h2',
                            classes: ['document-title'],
                        },
                        {
                            name: 'Subtitle',
                            element: 'h3',
                            classes: ['document-subtitle'],
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box'],
                        },
                        {
                            name: 'Side quote',
                            element: 'blockquote',
                            classes: ['side-quote'],
                        },
                        {
                            name: 'Marker',
                            element: 'span',
                            classes: ['marker'],
                        },
                        {
                            name: 'Spoiler',
                            element: 'span',
                            classes: ['spoiler'],
                        },
                        {
                            name: 'Code (dark)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-dark'],
                        },
                        {
                            name: 'Code (bright)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-bright'],
                        },
                    ],
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableProperties',
                        'tableCellProperties',
                    ],
                },
                translations: [translations],
            };
            if (this.textareaId !== null && this.textareaId !== undefined) {
                this.editorData = document.querySelector('#' + this.textareaId).textContent;
            }
            this.isLayoutReady = true;
        },
        methods: {
            onEditorBlur() {
                if (this.textareaId !== null && this.textareaId !== undefined) {
                    document.querySelector('#' + this.textareaId).textContent = this.editorData;
                }
            },
        },
    };
</script>
