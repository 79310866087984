export default {
    props: {
        valueEmpty: {
            default: null,
        },
    },
    methods: {
      getValueOrEmpty(value) {
        return value ? value : this.valueEmpty;
      },
    },
}
