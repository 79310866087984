var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.htmlTag,
    _vm._b(
      {
        tag: "component",
        class: _vm.getClasses,
        attrs: { title: _vm.stateData.infoText },
        on: { click: _vm.handleClick },
      },
      "component",
      _vm.linkAttributes,
      false
    ),
    [
      !_vm.stateData.icon ? _c("span", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _vm.stateData.icon ? _c("i", { class: _vm.stateData.icon }) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }