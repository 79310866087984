<template>
    <b-form-group :invalid-feedback="invalidFeedback" :label="label" :label-for="formControlId">
        <b-form-checkbox-group
            :id="formControlId"
            v-model="data[property]"
            :buttons="buttons"
            :disabled="isReadOnlyProperty"
            :name="formControlId"
            :options="options"
            :required="isRequiredProperty"
            :state="validationState"
            :switches="asSwitch"
            @change="emitChange">
        </b-form-checkbox-group>
    </b-form-group>
</template>

<script>
    import FormGroupMixin from '@components/form/FormGroupMixin';

    export default {
        mixins: [FormGroupMixin],
        props: {
            asSwitch: {
                type: Boolean,
                defaults: false,
            },
            options: {
                type: Array,
                default: () => [],
            },
            buttons: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['change'],
        computed: {
            hasLabelSlot() {
                return !!this.$slots.label;
            },
        },
        methods: {
            emitChange(value) {
                this.$emit('change', value);
            },
        },
    }
</script>
