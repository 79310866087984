import VueI18n from 'vue-i18n';
import VueRouter, {RouteConfig} from 'vue-router';
import Vue from 'vue';
import {ClassicEditor} from 'ckeditor5';
import {PluginFunction, PluginObject} from 'vue/types/plugin';

export default class VueFactory {
  private vueConstructorOptions: any = {
    data: {},
  };

  public createVue(rootComponent: any): Vue {
    return new Vue({
      ...this.vueConstructorOptions,
      methods: this.getGlobalMethods(),
      render: h => h(rootComponent),
    });
  }

  public createVueForDesktopApp(selector: string): Vue {
    return new Vue({'el': selector, ...this.vueConstructorOptions});
  }

  public getGlobalMethods(): any {
    return {
      setInputValue(value: any, setterCallback: any) {
        // We need to set the value to an empty string first to make sure the value is updated
        // event if the interal value has not changed. Example: input of 1.500 will not be
        // changed to 1.50 without this!
        setterCallback('');
        this.$nextTick(() => {
          setterCallback(value);
        });
      },
      // Ajax replacement of csp-ckeditor tag with Vue Version of CKEditor 5
      replaceCkeditor() {
        const ckeditorElements = document.querySelectorAll('csp-ckeditor');
        ckeditorElements.forEach((element) => {
          // Create a new Vue instance for each <cps-ckeditor> element
          new Vue({
            el: element,
            data() {
              return {
                editor: null as any,
              };
            },
            components: {
              'ckeditor': {
                template: '<div ref="editor"></div>',
                data() {
                  return {
                    editor: null as any,
                  };
                },
                mounted() {
                  this.$nextTick(() => {
                    if (this.$refs.editor) {
                      ClassicEditor.create(this.$refs.editor as HTMLElement)
                        .then(editor => {
                          console.log('Editor was initialized', editor);
                        })
                        .catch(error => {
                          console.error('There was a problem initializing the editor:', error);
                        });
                    }
                  });
                },
                beforeDestroy() {
                  if (this.editor) {
                    this.editor.destroy()
                      .then(() => {
                        this.editor = null;
                      })
                      .catch((error: any) => {
                        console.error('Error destroying CKEditor:', error);
                      });
                  }
                },
              },
            },
          });
        });
      },
    };
  }

  public initializeI18n(): VueI18n {
    Vue.use(VueI18n);

    const i18n = new VueI18n({
      locale: 'de_DE',
      messages: {},
    });

    this.vueConstructorOptions.i18n = i18n;
    return i18n;
  }

  public setData(data: any): void {
    this.vueConstructorOptions.data = data;
  }

  public usePlugin(plugin: PluginObject<any> | PluginFunction<any>, ...options: any[]): void {
    Vue.use(plugin, ...options);
  }

  public useRouter(routes: RouteConfig[], routerOptions: any = {}): void {
    if (routes.length === 0) {
      return;
    }
    Vue.use(VueRouter);
    this.vueConstructorOptions.router = new VueRouter({
      ...routerOptions,
      routes,
    });
  }
}
