<template>
    <tr :class="classes" :style="dragStyle"
        v-on="dragDetectionListeners"
        @click="handleRowClick" @click.middle.prevent="handleRowMiddleClick">
        <slot/>
    </tr>
</template>

<script>
    import DragDetectionMixin from '@components/mixins/DragDetectionMixin';

    export default {
        mixins: [DragDetectionMixin],
        props: {
            dataRecordUrl: {
                type: String,
                default: '',
            },
        },
        computed: {
            canDrop() {
                return !!this.$attrs['data-upload-record-id'];
            },
            classes() {
                return this.dataRecordUrl ? 'interactive' : '';
            },
        },
        methods: {
            handleRowClick(event) {
                if (!this.shouldHandleClick(event)) {
                    return;
                }

                window.location.href = this.dataRecordUrl;
            },
            handleRowMiddleClick(event) {
                if (!this.shouldHandleClick(event)) {
                    return;
                }

                window.open(this.dataRecordUrl, '_blank');
            },
            shouldHandleClick(event) {
                if (!this.dataRecordUrl) {
                    return false;
                }

                return event.target.tagName.toLowerCase() !== 'a';
            },
        },
    }
</script>
