<template>
    <div class="lds-ripple">
        <div/>
        <div/>
    </div>
</template>

<script>
    export default {}
</script>
