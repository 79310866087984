enum DateRangeTypeEnum {
  ENDLASTMONTH = 'endLastMonth',
  LAST_12_MONTHS = 'last12months',
  LAST_12_MONTHS_RELATIVE = 'last12monthsRelative',
  LAST_24_MONTHS_RELATIVE = 'last24monthsRelative',
  LASTMONTH = 'lastMonth',
  LASTYEAR = 'lastYear',
  MANUAL = 'manual',
  MONTHTODATE = 'monthToDate',
  THISWEEK = 'thisWeek',
  TODAY = 'today',
  UNTIL_TODAY = 'untilToday',
  UNTIL_TODAY_RELATIVE = 'untilTodayRelative',
  YEARTODATE = 'yearToDate'
}

export default DateRangeTypeEnum;
