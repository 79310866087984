import AjaxService from '@services/AjaxService';
import UrlParameterInterface from '@repositories/UrlParameterInterface';

export default class NoteCalendarRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public buildRecordNoteUrl(recordId: number, noteId: number): string {
    return '/de/Schaden-Manager/Akte/Vermerke/vehicleDamageRecordId/'
      + recordId + '?damageRecordNoteId=' + noteId;
  }

  public getCalendarEntries(parameters: UrlParameterInterface): Promise<any> {
    const url = '/api/noteCalendar';
    return this.ajaxService.getJsonWithParameters(url, parameters);
  }
}
