import HttpError from '@errors/HttpError';
import ValidationError from '@errors/ValidationError';

export default class AjaxPromiseBuilder {
  private errorCallback?: (reponse: any) => void;

  private readonly fetchConfig: any = {};

  private hasError = false;

  private originalResponse?: Response;

  private promiseReject: any;

  private promiseResolve: any;

  private successCallback?: (error: any) => void;

  private readonly url: string;

  public constructor(url: string, fetchConfig: any) {
    this.url = url;
    this.fetchConfig = fetchConfig;
  }

  public getPromise(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.promiseResolve = resolve;
      this.promiseReject = reject;
      fetch(this.url, this.fetchConfig)
        .then((response: Response) => this.getJsonResponseAndCheckForError(response))
        .then((response: Response) => this.handleNetworkSuccess(response))
        .catch((error: Error) => this.handleError(error));
    });
  }

  public setErrorCallback(errorCallback: (reponse: any) => void): void {
    this.errorCallback = errorCallback;
  }

  public setSuccessCallback(successCallback: (error: any) => void): void {
    this.successCallback = successCallback;
  }

  private buildErrorForResponse(response: Response | any): HttpError | ValidationError {
    if (!this.originalResponse) {
      throw 'The original response was not initialized!';
    }

    // If we do not have a valid JSON response we read the error from the response object.
    if (!(response.errors && response.errors.length > 0)) {
      return new HttpError(this.originalResponse.statusText, this.originalResponse.status);
    }

    if (this.originalResponse.status === 422) {
      return new ValidationError(this.originalResponse.statusText, response.errors);
    }

    const firstError = response.errors[0];
    return new HttpError(firstError.title, parseInt(firstError.status, 10));
  }

  private getJsonResponseAndCheckForError(response: Response): any {
    if (!response.ok) {
      this.hasError = true;
    }
    this.originalResponse = response;
    return response.json();
  }

  private handleError(error: Error | HttpError): void {
    if (this.errorCallback) {
      this.errorCallback(error);
    }
    this.promiseReject(error);
  }

  private handleNetworkSuccess(response: Response): void {
    if (this.hasError) {
      const error = this.buildErrorForResponse(response);
      this.handleError(error);
      return;
    }
    this.handleSuccess(response);
  }

  private handleSuccess(response: Response): void {
    if (this.successCallback) {
      this.successCallback(response);
    }
    this.promiseResolve(response);
  }
}
