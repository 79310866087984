<template>
    <vue-multiselect
        ref="multiselect"
        v-bind="{...commonProps, ...customProps}"
        v-on="commonEvents"
        @open="handleOpen">
        <template v-slot:noOptions>
            Keine Einträge vorhanden.
        </template>
        <template v-slot:noResult>
            Keine Übereinstimmungen gefunden.
        </template>
    </vue-multiselect>
</template>

<script>
    import {idMixin} from 'bootstrap-vue/src/mixins/id';
    import VueMultiselect from 'vue-multiselect';

    export default {
        components: {
            'vue-multiselect': VueMultiselect,
        },
        mixins: [idMixin],
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            options: {
                type: Array,
                default: () => [],
            },
            placeholder: {
                type: String,
                required: false,
                default: '',
            },
        },
        data() {
            return {
                searchTerm: '',
            };
        },
        computed: {
            commonProps() {
                const props = {
                    id: this.safeId(),
                    deselectLabel: this.deselectLabel,
                    disabled: this.disabled,
                    internalSearch: false,
                    label: 'text',
                    name: this.safeId(),
                    options: this.filteredOptions,
                    optionHeight: this.$root.multiselectOptionHeight,
                    placeholder: this.placeholderOrDefault,
                    selectLabel: this.$t('form.multiselect.select'),
                    selectedLabel: this.$t('form.multiselect.selected'),
                    trackBy: 'value',
                };
                if (this.isUsingOptionGroups) {
                    props.selectGroupLabel = 'Gruppe auswählen';
                    props.deselectGroupLabel = 'Gruppe abwählen';
                    props.groupValues = 'options';
                    props.groupLabel = 'optionGroup';
                    props.groupSelect = false;
                }
                return props;
            },
            commonEvents() {
                return {
                    'input': this.handleInput,
                    'search-change': this.handleSearchChange,
                }
            },
            customProps() {
                return {};
            },
            deselectLabel() {
                return this.$t('form.multiselect.deselect');
            },
            filteredOptions() {
                if (this.searchTerm === '') {
                    return this.options;
                }

                const searchTerms = this.searchTerm.toLocaleLowerCase().split(' ');

                if (!this.isUsingOptionGroups) {
                    return this.getFilteredOptions(this.options, searchTerms);
                }

                return this.getFilteredOptionGroups(this.options, searchTerms);
            },
            isUsingOptionGroups() {
                if (!this.options.length) {
                    return false;
                }
                return !!this.options[0].options;
            },
            placeholderOrDefault() {
                if (this.placeholder) {
                    return this.placeholder;
                }

                return this.$t('form.multiselect.placeholder');
            },
        },
        mounted() {
            this.initializeSelected();

            // This is a hack because vue-multiselect does not support a different placeholder
            // for the search input at the moment. TODO: create pull request for Vue Multiselect
            this.$refs.multiselect.$refs.search.setAttribute('placeholder', 'Suchbegriff eingeben...');
        },
        methods: {
            getFilteredOptions(options, searchTerms) {
                searchTerms.forEach((term) => {
                    options = options.filter((option) => {
                        return option.text.toLowerCase().includes(term);
                    });
                });
                return options;
            },
            getFilteredOptionGroups(optionGroups, searchTerms) {
                // We have to clone the option group here because otherwise the options
                // are removed from the original group due to object reference!
                let clonedOptionGroups = [];
                optionGroups.forEach((optionGroup) => {
                    clonedOptionGroups.push({...optionGroup});
                });

                searchTerms.forEach((term) => {
                    clonedOptionGroups = clonedOptionGroups.filter((optionGroup) => {
                        optionGroup.options = optionGroup.options.filter((option) => {
                            return option.text.toLowerCase().includes(term);
                        });
                        return optionGroup.options.length > 0;
                    });
                });

                return clonedOptionGroups;
            },
            getFlatOptions(options) {
                if (!this.isUsingOptionGroups) {
                    return options;
                }

                let flatOptions = [];
                options.forEach((optionGroup) => {
                    flatOptions = flatOptions.concat(optionGroup.options);
                });
                return flatOptions;
            },
            handleOpen() {
                // This is a workaround to scroll to the first selected option, see:
                // https://github.com/shentao/vue-multiselect/issues/1021#issuecomment-632097606
                // TODO: remove when fixed in upstream.
                const select = this.$refs.multiselect;
                let position = select.filteredOptions.findIndex((option) => {
                    if (!option || option.$isLabel) {
                        return false;
                    }
                    return this.isOptionSelected(option)
                });
                if (position < 1) {
                    return;
                }
                select.pointerSet(position)
                this.$nextTick(() => {
                    select.$refs.list.scrollTop = select.pointerPosition
                });
            },
            handleSearchChange(query) {
                this.searchTerm = query;
            },
            handleInput() {
                // Override in concrete component!
            },
            initializeSelected() {
                // Overriden in concrete component!
            },
            isOptionSelected() {
                // Overriden in concrete component!
            },
        },
    }
</script>
