import AjaxService from '@services/AjaxService';
import Vue from 'vue';
import ItemDataSkeletton from '@js/jsonapi/skeletton/ItemDataSkeletton';

export default class ApplicationRepository {
  private readonly ajaxService: AjaxService;

  private store = new Vue({
    data: {
      applicationData: new ItemDataSkeletton(),
    },
  });

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public getLoginFormUrl(): string {
    if (!this.store.applicationData.attributes.loginFormUrl) {
      return '';
    }
    return this.store.applicationData.attributes.loginFormUrl;
  }

  public initialize(): Promise<any> {
    const promise = this.ajaxService.getJson('/api/applications');
    promise.then((response) => {
      this.store.applicationData = response.data;
    });
    return promise;
  }

  public isEnvironmentDevelopment(): boolean {
    if (!this.store.applicationData.attributes.environment) {
      return false;
    }
    return this.store.applicationData.attributes.environment === 'development';
  }
}
