<template>
    <b-form-group :invalid-feedback="invalidFeedback" :label="label" :label-for="formControlId" v-bind="$attrs">
        <b-form-select
            :id="formControlId"
            v-model="data[property]"
            :disabled="isReadOnlyProperty"
            :name="formControlId"
            :options="options"
            :required="isRequiredProperty"
            :state="validationState"
            @change="$emit('change')"/>
    </b-form-group>
</template>

<script>
    import FormGroupMixin from '@components/form/FormGroupMixin';

    export default {
        mixins: [FormGroupMixin],
        props: {
            options: {
                type: Array,
                required: true,
            },
        },
        emits: ['change'],
    }
</script>
