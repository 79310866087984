import AjaxService from '@services/AjaxService';

export default class ErrorRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public store(message: string): void {
    // noinspection JSIgnoredPromiseFromCall
    this.ajaxService.postJson('/api/errors', {data: {attributes: {message}}});
  }
}
