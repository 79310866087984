import AjaxService from '@services/AjaxService';

export default class SupportRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public store(attributes: { [key: string]: any; }): Promise<any> {
    const url = '/api/support';
    return this.ajaxService.postJson(url, {data: {attributes}});
  }
}
