import './desktop.scss';
import './app.js'

const csp = {
    loadFormSubAmount(selector, options) {
        import(/* webpackChunkName: "desktop/widget/form/sub-amount" */ './widget/form/sub-amount').then(
            () => {
                jQuery(selector).subAmountForm(options);
            }
        );
    },
    loadFormCostUnitPayment(selector, options) {
        import(/* webpackChunkName: "desktop/widget/form/cost-unit-payment" */ './widget/form/cost-unit-payment').then(
            () => {
                jQuery(selector).formCostUnitPayment(options);
            }
        );
    },
    loadFormInsurance(selector) {
        import(/* webpackChunkName: "desktop/widget/form/insurance" */ './widget/form/insurance').then(
            () => {
                jQuery(selector).formInsurance();
            }
        );
    },
    loadSelectableDateRanges(selector, options) {
        import(/* webpackChunkName: "desktop/widget/form/selectable-date-ranges" */ './widget/form/selectable-date-ranges').then(
            () => {
                jQuery(selector).selectableDateRanges(options);
            }
        );
    },
}

export default csp;
window.csp = csp;
