import AjaxService from '@services/AjaxService';

interface LegacyOption {
  key: string,
  value: string
}

interface OptionsReponse {
  options: LegacyOption[]
}

interface SelectOptionNumber {
  text: string,
  value: number
}

export default class FileCategoryRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public findCategoriesDependingOnGroup(groupId: number, recordId: string): Promise<SelectOptionNumber[]> {
    const url = '/de/Portal/ajax/dropdown/contentType/fileCategory/format/json?fileCategoryGroupId=' + groupId
      + '&checkAcl=true&module=damage&vehicleDamageRecordId=' + recordId;

    return this.ajaxService.getDropdownOptionsLegacy(url)
      .then((response: OptionsReponse) => {
        const options: SelectOptionNumber[] = [];
        for (const optionData of response.options) {
          options.push({
            value: parseInt(optionData.key, 10),
            text: optionData.value,
          })
        }
        return options;
      });
  }
}
