import AjaxService from '@services/AjaxService';
import Form from '@form/Form';

export default class AuthRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public authenticatePasswordResetToken(token: string): Promise<any> {
    const url = '/api/password-reset-token-auth';
    return this.ajaxService.postJson(url, {data: {attributes: {token}}});
  }

  public getPartialAuthenticationStatus(): Promise<any> {
    const url = '/api/auth/partial-status';
    return this.ajaxService.getJson(url);
  }

  public getUserInfo(): Promise<any> {
    const url = '/api/auth';
    return this.ajaxService.getJson(url);
  }

  public login(username: string, password: string): Promise<any> {
    const url = '/api/auth';
    return this.ajaxService.postJson(url, {data: {attributes: {username, password}}});
  }

  public loginByExternalPin(pin: string, damageRecordId: number): Promise<any> {
    const url = '/api/login-by-external-pin';
    return this.ajaxService.postJson(url, {data: {attributes: {pin, damageRecordId}}});
  }

  public loginSecondFactor(form: Form): Promise<any> {
    const url = '/api/auth/second-factor';
    return this.ajaxService.postFormData(url, form);
  }

  public logout(): Promise<any> {
    const url = '/api/auth/logout';
    return this.ajaxService.postJson(url, {});
  }

  public resetPassword(form: Form): Promise<any> {
    const url = '/api/password-reset';
    return this.ajaxService.postFormData(url, form);
  }
}
