import AjaxService from '@services/AjaxService';

export default class MessageRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public delete(messageId: number): Promise<any> {
    const url = '/api/messages/' + messageId;
    return this.ajaxService.sendDelete(url);
  }

  public getStats(): Promise<any> {
    const url = '/api/messages/stats';
    return this.ajaxService.getJson(url);
  }

  public index(): Promise<any> {
    const url = '/api/messages';
    return this.ajaxService.getJson(url);
  }

  public indexImportant(): Promise<any> {
    const url = '/api/messages/important';
    return this.ajaxService.getJsonWithInclude(
      url,
      [
        'damageRecordNote',
        'damageRecordNote.damageRecord',
      ],
      ['damageRecordNote.damageRecord.state']
    );
  }

  public markCompleted(messageId: string): Promise<any> {
    const url = '/api/messages/' + messageId + '/mark-completed';
    return this.ajaxService.postJson(url, {});
  }

  public show(messageId: string): Promise<any> {
    const url = '/api/messages/' + messageId;
    return this.ajaxService.getJson(url);
  }
}
