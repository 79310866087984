import ValidationError from '../../lib/errors/ValidationError';
import AjaxError from '../../lib/services/ajax/AjaxError';
import HttpError from '../../lib/errors/HttpError';
import EventBus from '../../lib/services/EventBus';
import FlashMessenger from '../../lib/services/FlashMessenger';
import StackTrace from 'stacktrace-js';
import ErrorRepository from '@repositories/ErrorRepository';
import FlashMessage from '@services/flashmessages/FlashMessage';

export default class ErrorHandler {
  private readonly errorRepository: ErrorRepository;

  private readonly eventBus: EventBus

  private readonly flashMessenger: FlashMessenger;

  private suppressMaintenanceError = false;

  private suppressValidationErrors = false;

  public constructor(errorRepository: ErrorRepository, eventBus: EventBus, flashMessenger: FlashMessenger) {
    this.errorRepository = errorRepository;
    this.eventBus = eventBus;
    this.flashMessenger = flashMessenger;
  }

  public attachErrorLoggerToWindow(contentWindow: Window): void {
    contentWindow.onerror = (msg, url, lineNo, columnNo, error) => {
      const cancelDefaultErrorHandling = false;

      if (error instanceof Error) {
        this.logStackTrace(error);
        return cancelDefaultErrorHandling;
      }

      this.logError(msg + ', URL: ' + url + ', lineNo: ' + lineNo + ', columnNo: ' + columnNo);
      return cancelDefaultErrorHandling;
    }
  }

  public initialize(): void {
    this.eventBus.on('ajax-error', (ajaxError: AjaxError) => {
      const error = ajaxError.getError();
      if (!this.suppressValidationErrors && error instanceof ValidationError) {
        this.flashMessenger.addWarning('Die eingegeben Daten sind ungültig. Bitte prüfen Sie das Formular auf Fehler.');
        return;
      }

      if (!(error instanceof HttpError)) {
        return;
      }

      this.handleHttpError(error);
    });

    this.eventBus.on('ajax-success', (response: any) => {
      if (!response.meta) {
        return;
      }
      if (!Array.isArray(response.meta.flashMessages)) {
        return;
      }
      response.meta.flashMessages.forEach((flashMessage: any) => {
        this.flashMessenger.addFromResponse(flashMessage);
      });
    });
  }

  public logError(message: string): void {
    this.errorRepository.store(message);
  }

  public logStackTrace(error: Error, additionalInfo = ''): void {
    StackTrace.fromError(error).then((stackframes) => {
      const stringifiedStack = stackframes.map(function(sf) {
        return sf.toString();
      });
      let errorMessage = error.message;
      errorMessage += '\n\n' + stringifiedStack.join('\n');
      if (additionalInfo) {
        errorMessage += '\n\nAdditional info: ' + additionalInfo;
      }
      this.logError(errorMessage);
    });
  }

  public showValidationErrors(): void {
    this.suppressValidationErrors = false;
  }

  public silenceValidationErrors(): void {
    this.suppressValidationErrors = true;
  }

  private handleHttpError(error: HttpError): void {
    if (error.getStatusCode() === 503 && !this.suppressMaintenanceError) {
      this.suppressMaintenanceError = true;
      window.setTimeout(
        () => {
          this.suppressMaintenanceError = false
        },
        500
      );
      this.flashMessenger.addWarning(
        'Aktuell werden Wartungsarbeiten am System durchgeführt. ' +
        'Bitte versuchen Sie es in einigen Minuten erneut.'
      );
    }

    if (error.getStatusCode() === 403) {
      this.flashMessenger.addError('Der Zugriff wurde verweigert.');
    }

    if (error.getStatusCode() === 404) {
      this.flashMessenger.addWarning('Der angeforderte Datensatz wurde nicht gefunden.');
    }

    if (error.getStatusCode() === 500) {
      this.flashMessenger.addError(
        'Es ist ein unerwarteter Fehler aufgetreten. ' +
        'Sollte das Problem wiederholt auftreten, wenden Sie sich bitte an den Support.'
      );
    }
  }
}
