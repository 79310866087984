import AjaxService from '@services/AjaxService';

export default class FirmLevelRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public index(): Promise<any> {
    const url = '/api/firmlevels';
    return this.ajaxService.getJson(url);
  }

  public update(data: any): Promise<any> {
    const url = '/api/firmlevels';
    return this.ajaxService.postJson(url, {data: {attributes: data}});
  }
}
