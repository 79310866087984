import AjaxService from '@services/AjaxService';
import DateRangeTypeEnum from '@js/enums/DateRangeTypeEnum';
import {dateAsString, dateNowAsString} from '@js/functions/date';
import moment from 'moment';
import UrlParameterInterface from '@repositories/UrlParameterInterface';

interface LegacyOption {
  key: string,
  value: string
}

interface LegacyGroupedOption {
  key: string,
  value: LegacyOption[]
}

interface OptionsResponse {
  options: LegacyOption[]
}

interface GroupedOptionsResponse {
  options: LegacyGroupedOption[]
}

interface SelectOptionNumber {
  text: string,
  value: number | string
}

interface SelectOptionGroupNumber {
  optionGroup: string,
  options: SelectOptionNumber[]
}

export default class AnalyticRepository {
  private readonly ajaxService: AjaxService;

  private filterParams: UrlParameterInterface = {};
  private filters = {};

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public static getDefaultFilters(): { [filter: string]: any; } {
    return {
      areaId: '',
      firmAssociationIdOrGroup: '',
      firmGroupId: '',
      firmId: '',
      responsibleUserId: '',
      closedByFirmId: '',
      selectInterval: DateRangeTypeEnum.LAST_12_MONTHS_RELATIVE,
      intervalDateRelative: dateNowAsString(),
      intervalDateBegin: dateAsString(moment().format('YYYY') + '-01-01'),
      intervalDateEnd: dateNowAsString(),
    }
  }

  public findForDropdown(url: string, params: UrlParameterInterface = {}): Promise<SelectOptionNumber[]> {
    return this.ajaxService.getDropdownOptionsLegacy(url, params)
      .then((response: OptionsResponse) => {
        const options: SelectOptionNumber[] = [];
        for (const optionData of response.options) {
          options.push({
            value: parseInt(optionData.key, 10),
            text: optionData.value,
          })
        }
        return options;
      });
  }

  public findForGroupedDropdown(url: string, params: UrlParameterInterface = {}): Promise<SelectOptionGroupNumber[]> {
    return this.ajaxService.getDropdownOptionsLegacy(url, params)
      .then((response: GroupedOptionsResponse) => {
        const options: SelectOptionGroupNumber[] = [];
        for (const optionData of response.options) {
          const subOptions: SelectOptionNumber[] = [];
          for (const subOptionData of optionData.value) {
            subOptions.push({
              value: subOptionData.key,
              text: subOptionData.value,
            })
          }

          options.push({
            optionGroup: optionData.key,
            options: subOptions,
          })
        }
        return options;
      });
  }

  public getAccidentUntilRecordCreationTime(): Promise<any> {
    return this.getAnalytics('accidentUntilRecordCreationTime');
  }

  public getAccidentUntilRecordCreationTimeGrouped(): Promise<any> {
    return this.getAnalytics('grouped/accidentUntilRecordCreationTime');
  }

  public getApprovedDamageRecordsCount(): Promise<any> {
    return this.getAnalytics('approvedDamageRecordsCount');
  }

  public getDamageRecordsTrend(): Promise<any> {
    return this.getAnalytics('damageRecordsTrend');
  }

  public getEnforcementQuote(): Promise<any> {
    return this.getAnalytics('enforcementQuote');
  }

  public getEnforcementQuoteGrouped(): Promise<any> {
    return this.getAnalytics('grouped/enforcementQuoteCasualty');
  }

  public getEnforcementQuoteGroupedComprehensive(): Promise<any> {
    return this.getAnalytics('grouped/enforcementQuoteComprehensive');
  }

  public getFilterData(): Promise<any> {
    const url = '/api/analytics/filter/index';
    return this.ajaxService.getJson(url);
  }

  public getFilters(): { [filter: string]: any; } {
    if (!Object.keys(this.filters).length) {
      return AnalyticRepository.getDefaultFilters();
    }
    return this.filters;
  }

  public getInquiryResponseTimes(): Promise<any> {
    return this.getAnalytics('inquiryResponseTimes');
  }

  public getInquiryResponseTimesGrouped(): Promise<any> {
    return this.getAnalytics('grouped/inquiryResponseTimes');
  }

  public getOutstandingPositions(): Promise<any> {
    return this.getAnalytics('outstandingPositions');
  }

  public getPaymentDaysAvg(): Promise<any> {
    return this.getAnalytics('paymentDaysAvg');
  }

  public getPaymentDaysAvgGrouped(): Promise<any> {
    return this.getAnalytics('grouped/paymentDaysAvgCasualty');
  }

  public getPaymentDaysAvgGroupedComprehensive(): Promise<any> {
    return this.getAnalytics('grouped/paymentDaysAvgComprehensive');
  }

  public getProcessingStatusCounts(): Promise<any> {
    return this.getAnalytics('processingStatusCounts');
  }

  public getProcessingStatusCountsCasualty(): Promise<any> {
    return this.getAnalytics('processingStatusCountsCasualty');
  }

  public getProcessingStatusCountsComprehensive(): Promise<any> {
    return this.getAnalytics('processingStatusCountsComprehensive');
  }

  public getProcessingStatusCountsDspGrouped(): Promise<any> {
    return this.getAnalytics('grouped/processingStatusCountsDsp');
  }

  public getProcessingStatusCountsLawyerGrouped(): Promise<any> {
    return this.getAnalytics('grouped/processingStatusCountsLawyer');
  }

  public getRecordCreationUntilResellerRequested(): Promise<any> {
    return this.getAnalytics('recordCreationUntilResellerRequested');
  }

  public getRecordCreationUntilResellerRequestedGrouped(): Promise<any> {
    return this.getAnalytics('grouped/recordCreationUntilResellerRequested');
  }

  public getRentVehicleQuote(): Promise<any> {
    return this.getAnalytics('rentVehicleQuote');
  }

  public getRentVehicleQuoteGrouped(): Promise<any> {
    return this.getAnalytics('groupedRentVehicleQuote');
  }

  public getVehicleRepairQuote(): Promise<any> {
    return this.getAnalytics('vehicleRepairQuote');
  }

  public getVehicleRepairQuoteGrouped(): Promise<any> {
    return this.getAnalytics('grouped/vehicleRepairQuoteCasualty');
  }

  public getVehicleRepairQuoteGroupedComprehensive(): Promise<any> {
    return this.getAnalytics('grouped/vehicleRepairQuoteComprehensive');
  }

  public setAnalyticFilters(filters: { [filter: string]: any; }): void {
    this.filters = filters;
    this.filterParams = this.getParsedFilterParams(filters);
  }

  private getAnalytics(analyticsType: string): Promise<any> {
    const url = '/api/analytics/' + analyticsType;

    if (!Object.keys(this.filterParams).length) {
      return this.ajaxService.getJson(url);
    }

    return this.ajaxService.getJsonWithParameters(url, this.filterParams);
  }

  private getParsedFilterParams(filterParams: any): UrlParameterInterface {
    const params: UrlParameterInterface = {};
    Object.keys(filterParams).map(function(key){
      if (!filterParams[key]) {
        filterParams[key] = '';
      }

      params[key] = filterParams[key];
      return params;
    });

    return params;
  }
}
