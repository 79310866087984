import AjaxService from '@services/AjaxService';
import UrlParameterInterface from '@repositories/UrlParameterInterface';

export default class ReceiptRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public buildItemizedListingExportUrl(receiptId: number): string {
    return `/api/receipts/${receiptId}/itemized-listing`;
  }

  public buildSpreadsheetExportUrl(filters: UrlParameterInterface): string {
    const url = '/api/receipts/export/spreadsheet';
    return this.ajaxService.appendParametersToUrl(url, filters);
  }

  public deleteReceipt(receiptId: number): Promise<any> {
    const url = `/api/receipts/${receiptId}`;
    return this.ajaxService.sendDelete(url);
  }

  public exportXml(): Promise<any> {
    const url = '/api/receipts/export/xml';
    return this.ajaxService.postJson(url, {});
  }

  public generateReceipt(generateReceiptFormData: any): Promise<any> {
    const url = '/api/receipts';
    return this.ajaxService.postJson(url, {data: {attributes: generateReceiptFormData}});
  }

  public getMissingData(generateReceiptFormData: any): Promise<any> {
    const url = '/api/receipts/missing-data';
    return this.ajaxService.postJson(url, {data: {attributes: generateReceiptFormData}});
  }

  public getReceiptGenerationStatus(): Promise<any> {
    const url = '/api/receipts/status';
    return this.ajaxService.getJson(url);
  }

  public getReceipts(parameters: UrlParameterInterface): Promise<any> {
    const url = '/api/receipts';
    return this.ajaxService.getJsonWithParameters(url, parameters);
  }

  public updateReceipt(receiptId: number, receiptData: any): Promise<any> {
    const url = `/api/receipts/${receiptId}`;
    return this.ajaxService.putJson(url, receiptData);
  }
}
