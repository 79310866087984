import * as merge from 'deepmerge';
import EventBus from '@services/EventBus';
import AjaxError from '@services/ajax/AjaxError';
import AjaxPromiseBuilder from '@services/ajax/AjaxPromiseBuilder';
import LoadingState from '@services/loading/LoadingState';
import Form from '@form/Form';
import UrlParameterInterface from '@repositories/UrlParameterInterface';

export default class AjaxService {
  private defaultHeaders: { [header: string]: string; } = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'X-Requested-With': 'XMLHttpRequest',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/vnd.api+json',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Accept': 'application/vnd.api+json',
  };

  private readonly document: Document;

  private readonly eventBus: EventBus;

  public constructor(eventBus: EventBus) {
    this.eventBus = eventBus;

    this.document = document;
    // this.detectCsrfToken();
  }

  public appendParametersToUrl(url: string, parameters: UrlParameterInterface): string {
    if (parameters.length === 0) {
      return url;
    }
    const urlInstance = new URL(url, location.href);
    for (const parameter in parameters) {
      const value = parameters[parameter];
      if (!Array.isArray(value)) {
        urlInstance.searchParams.set(parameter, '' + value)
        continue;
      }
      value.forEach((value) => {
        urlInstance.searchParams.append(parameter, '' + value)
      })
    }
    return urlInstance.toString();
  }

  public getCsrfToken(): string {
    return this.defaultHeaders['X-CSRF-TOKEN'];
  }

  public getDropdownOptionsLegacy(url: string, urlParameters: UrlParameterInterface = {}): Promise<any> {
    url = this.appendParametersToUrl(url, urlParameters);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.getJsonWithHeaders(url, {'X-Csp-Disable-Version-Redirect': 'true'});
  }

  public getJson(url: string): Promise<any> {
    const fetchConfig = this.getFetchConfig();
    return this.buildAjaxPromise(url, fetchConfig);
  }

  public getJsonWithHeaders(url: string, headers: { [header: string]: string; }): Promise<any> {
    const fetchConfig = this.getFetchConfig({headers: headers});
    return this.buildAjaxPromise(url, fetchConfig);
  }

  /**
   * Get fractal with all includes specified in the include array
   * @see https://fractal.thephpleague.com/transformers/
   *
   * @param url
   * @param includes
   * @param excludes
   */
  public getJsonWithInclude(url: string, includes: string[], excludes: string[]): Promise<any> {
    const params: UrlParameterInterface = {};
    if (includes.length) {
      params['include[]'] = includes;
    }
    if (excludes.length) {
      params['exclude[]'] = excludes;
    }
    return this.getJsonWithParameters(url, params);
  }

  public getJsonWithParameters(url: string, urlParameters: UrlParameterInterface): Promise<any> {
    const urlWithParameters = this.appendParametersToUrl(url, urlParameters);
    return this.getJson(urlWithParameters);
  }

  public postFormData(url: string, form: Form): Promise<any> {
    return this.buildAjaxPromiseForForm(url, 'POST', form);
  }

  public postJson(url: string, data: any): Promise<any> {
    const fetchConfig = this.getFetchConfigWithJsonData(data, 'POST');
    return this.buildAjaxPromise(url, fetchConfig);
  }

  public putFormData(url: string, form: Form): Promise<any> {
    return this.buildAjaxPromiseForForm(url, 'PUT', form);
  }

  public putJson(url: string, data: any): Promise<any> {
    const fetchConfig = this.getFetchConfigWithJsonData(data, 'PUT');
    return this.buildAjaxPromise(url, fetchConfig);
  }

  public sendDelete(url: string): Promise<any> {
    const fetchConfig = this.getFetchConfig({
      'method': 'DELETE',
    });
    return this.buildAjaxPromise(url, fetchConfig);
  }

  private addSessionIdQueryParameter(url: string): string {
    const urlParameters = new URLSearchParams(window.location.search);
    const sessionId = urlParameters.get('PHPSESSID');
    if (!sessionId) {
      return url;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.appendParametersToUrl(url, {PHPSESSID: sessionId});
  }

  private buildAjaxPromise(url: string, fetchConfig: any): Promise<any> {
    url = this.addSessionIdQueryParameter(url);
    this.startLoading(url);
    const ajaxPromiseBuilder = new AjaxPromiseBuilder(url, fetchConfig);
    ajaxPromiseBuilder.setErrorCallback((error: Error) => this.handleError(error, url));
    ajaxPromiseBuilder.setSuccessCallback((response: any) => this.handleSuccess(response, url));
    return ajaxPromiseBuilder.getPromise();
  }

  private buildAjaxPromiseForForm(url: string, method: string, form: Form): Promise<any> {
    const fetchConfig = this.getFetchConfigWithJsonData({data: {attributes: form.getData()}}, method);
    if (form.getLabelTranslationKeyPrefix()) {
      fetchConfig.headers['X-Validation-Label-Translation-Key-Prefix'] = form.getLabelTranslationKeyPrefix();
    }
    return this.buildAjaxPromise(url, fetchConfig);
  }

  private detectCsrfToken(): void {
    const token: Element | null = this.document.head.querySelector('meta[name="csrf-token"]');

    if (token) {
      this.defaultHeaders['X-CSRF-TOKEN'] = token.getAttribute('content') + '';
    }
    else {
      console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
    }
  }

  private getFetchConfig(additionalConfig: any = {}): any {
    const defaultConfig = {
      headers: this.defaultHeaders,
      credentials: 'same-origin',
    };
    return merge.all([defaultConfig, additionalConfig]);
  }

  private getFetchConfigWithJsonData(data: any, method: string): any {
    return this.getFetchConfig(
      {
        method: method,
        body: JSON.stringify(data),
      }
    );
  }

  private handleError(error: Error, url: string): void {
    this.stopLoading(url);
    this.eventBus.emit(
      'ajax-error',
      new AjaxError(
        'Bei der Anfrage ist ein Fehler aufgetreten: ' + error.message,
        error
      )
    );
  }

  private handleSuccess(reponse: any, url: string): void {
    this.stopLoading(url);
    this.eventBus.emit('ajax-success', reponse);
  }

  private startLoading(url: string): void {
    this.eventBus.emit(LoadingState.EVENT_LOADING_START, url);
  }

  private stopLoading(url: string): void {
    this.eventBus.emit(LoadingState.EVENT_LOADING_END, url);
  }
}
