enum FirmCategoryEnum {
  APPRAISER = 6,
  CALLCENTER = 5,
  CARDEALER = 1,
  DAMAGE_SERVICE_PROVIDER = 11,
  LAWYER = 2,
  RESELLER = 3,
  SERVICE_PROVIDER_DETAILER = 9,
  SERVICE_PROVIDER_LOGISTICIAN = 8,
  SERVICE_PROVIDER_PAINT_SHOP = 7,
  SYSTEM = 4
}

export default FirmCategoryEnum;
