<script>
    export default {
        inheritAttrs: false,
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            form: {
                type: Object,
                required: true,
            },
            hideLabel: {
                type: Boolean,
                default: false,
            },
            idSuffix: {
                type: String,
                default: '',
            },
            labelProperty: {
                type: String,
                default: '',
            },
            overrideInvalidFeedback: {
                type: String,
                default: null,
            },
            overrideState: {
                type: [String, Boolean],
                default: '',
            },
            potentiallyRequired: {
                type: Boolean,
            },
            property: {
                type: String,
                required: true,
            },
            required: {
                type: Boolean,
            },
        },
        computed: {
            data() {
                return this.form.getData()
            },
            formControlId() {
                let id = 'form-field-' + this.form.getContext() + '-' + this.property.toLocaleLowerCase();
                if (this.idSuffix) {
                    id += '-' + this.idSuffix;
                }
                return id;
            },
            invalidFeedback() {
                if (this.overrideInvalidFeedback !== null) {
                    return this.overrideInvalidFeedback;
                }
                return this.form.getInvalidFeedback(this.property);
            },
            isReadOnlyProperty() {
                if (this.disabled) {
                    return true;
                }
                return this.form.isPropertyReadOnly(this.property);
            },
            isRequiredProperty() {
                return this.required && !this.form.isPropertyAllowedToBeEmpty(this.property);
            },
            label() {
                if (this.hideLabel) {
                    return '';
                }
                return this.labelTranslation + (this.isRequiredProperty ? '*' : '');
            },
            labelPropertyValue() {
                return this.labelProperty ? this.labelProperty : this.property;
            },
            labelTranslation() {
                return this.$t('form field ' + this.form.getLabelTranslationKeyPrefix() + this.labelPropertyValue);
            },
            validationState() {
                if (this.overrideState !== '') {
                    return this.overrideState;
                }
                return this.form.getValidationState(this.property);
            },
        },
    }
</script>
