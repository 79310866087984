<script>
    export default {
        props: {
            popoverEnabled: {
                type: Boolean,
                default: false,
            },
            popoverPlacement: {
                type: String,
                default: 'top',
            },
            stateOverviewClasses: {
                type: String,
                default : '',
            },
            showTextInfo: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            stateOverviewProps() {
                return {
                    popoverPlacement: this.popoverPlacement,
                    stateOverviewClasses: this.stateOverviewClasses,
                    showTextInfo: this.showTextInfo,
                }
            },
        },
    }
</script>
