var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "card-foldable", attrs: { "no-body": "" } },
    [
      _c(
        "b-link",
        { class: _vm.collapseLinkClasses, on: { click: _vm.toggleVisibility } },
        [
          _c(
            "span",
            {
              staticClass: "text-uppercase font-weight-bold foldable-headline",
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: {
            id: _vm.collapseId,
            accordion: "my-accordion",
            role: "tabpanel",
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [_c("b-card", [_vm.visible ? _vm._t("default") : _vm._e()], 2)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }