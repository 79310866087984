import {RawLocation, Route, VueRouter} from 'vue-router/types/router';
import Vue from 'vue';

export default class BeforeRouteChangeEvent {
  private readonly navigateTo: RawLocation | null = null;

  private readonly nextCallback: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void;

  private readonly routeFrom: Route;

  private readonly routeTo: Route;

  private readonly router: VueRouter;

  public constructor(
    router: VueRouter,
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
  ) {
    this.nextCallback = next;
    this.router = router;
    this.routeFrom = from;
    this.routeTo = to;
  }

  public getNext(): (to?: RawLocation | false | ((vm: Vue) => any) | void) => void {
    return this.nextCallback;
  }

  public getRouteFrom(): Route {
    return this.routeFrom;
  }

  public getRouteTo(): Route {
    return this.routeTo;
  }

  public getRouter(): VueRouter {
    return this.router;
  }

  public next(to?: RawLocation | false | ((vm: Vue) => any) | void): void {
    this.nextCallback(to);
  }
}
