var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLayoutReady
        ? _c("ckeditor", {
            ref: "editor",
            attrs: { config: _vm.config, editor: _vm.editor },
            on: { blur: _vm.onEditorBlur },
            model: {
              value: _vm.editorData,
              callback: function ($$v) {
                _vm.editorData = $$v
              },
              expression: "editorData",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }