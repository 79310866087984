import AjaxService from '@services/AjaxService';
import Build from '@build/Build';
import EventBus from '@services/EventBus';

export default class TranslationRepository {
  private readonly ajaxService: AjaxService;

  private reportMissingTranslations = false;

  public constructor(ajaxService: AjaxService, eventBus: EventBus) {
    this.ajaxService = ajaxService;
    eventBus.on('translations-loaded', () => {
      this.reportMissingTranslations = true;
    });
  }

  public loadTranslation(): Promise<any> {
    return this.ajaxService.getJson('/api/translations');
  }

  public reportMissing(translationKey: string): void {
    if (!Build.shouldReportMissingTranslations()) {
      return;
    }
    if (!this.reportMissingTranslations) {
      return;
    }
    this.ajaxService.postJson('/api/translations', {data: {attributes: {translationKey}}}).then(
      null,
      (error) => console.error(error)
    );
  }
}
