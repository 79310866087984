<template>
    <div class="record-state-overview-wrapper">
        <div ref="wrapper" :class="wrapperClasses" @click="handleClick">
            <csp-record-state-badge v-for="(stateData, index) in stateDataItems" :key="index"
                :class="isPopoverEnabled ? 'skip-row-click clickable' : ''"
                :state-data="stateData"/>
        </div>

        <b-popover v-if="isPopoverEnabled" ref="popover" custom-class="clickable"
            :placement="popoverPlacement" :show="popoverVisible" :target="wrapperRef" triggers="">
            <template v-slot:title>
                Status
            </template>

            <p v-for="(stateData, index) in stateDataItems" :key="index" class="mb-1">
                {{ stateData.infoText }}
            </p>
        </b-popover>

        <div v-if="showTextInfo" class="record-state-info-text">
            <ul>
                <li v-for="(stateData, index) in stateDataItems" :key="index">
                    {{ stateData.infoText }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import RecordStateBadge from './StateBadge';
    import StateOverviewMixin from './StateOverviewMixin';
    import StateOverviewPopoverMixin from './StateOverviewPopoverMixin';

    export default {
        components: {
            'csp-record-state-badge': RecordStateBadge,
        },
        mixins: [
            StateOverviewMixin,
            StateOverviewPopoverMixin,
        ],
        props: {
            stateDataItems: {
                type: Array,
                required: true,
            },
        },
        computed: {
            wrapperClasses() {
                let classes = ['record-state-overview'];

                if (this.stateOverviewClasses) {
                    classes.push(this.stateOverviewClasses);
                }

                if (this.isPopoverEnabled) {
                    classes.push('skip-row-click clickable');
                }

                return classes;
            },
        },
        methods: {
            handleClick() {
                this.togglePopover();
            },
        },
    }
</script>
